// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector) // Detects the user's language
    .use(initReactI18next) // Passes i18n instance to react-i18next.
    .init({
        resources: {
            en: {
                translation: {
                    "Dashboard": "Dashboard",
                    "Live Ranking": "Live Ranking",
                    "Pledges": "Pledges",
                    "Update Score": "Update Score",
                    "Resources": "Resources",
                    "Forum": "Forum",
                    "Contact Us": "Contact Us",
                    "Videos": "Videos",
                    "Blogs": "Blogs",
                    "Posters": "Posters",
                    "Quotes": "Quotes",
                    "Logout Account": "Logout Account",
                    "language": "Language",
                    "Pages": "Pages",
                    "500+ Pages": "500+ Pages",
                    "300-500 Pages": "300-500 Pages",
                    "150-300 Pages": "150-300 Pages",
                    "100-150 Pages": "100-150 Pages",
                    "10-50 Pages": "10-50 Pages",
                    "English Book": "English Book",
                    "Non-English Book": "Non-English Book",
                    "ChaitanyaCharitamritaSet": "Chaitanya Charitamrita Set",
                    "SrimadBhagavatamSet": "Srimad Bhagavatam Set",
                    "MahabigBooks": "Mahabig Books",
                    "BigBooks": "Big Books",
                    "MediumBooks": "Medium Books",
                    "SmallBooks": "Small Books",
                    "Magazines": "Magazines",
                    "1 Year BTG Subscription-1": "1 Year BTG Subscription-1",
                    "1 Year BTG Subscription-2": "1 Year BTG Subscription-2",
                    "Finished": "Finished",
                    "Unfinished": "Unfinished",
                    "In Progress": "In Progress",
                    "Top 10": "Top 10",
                    "Temples": "Temples",
                    "in": "in",
                    "Big Temple": "Big Temple",
                    "Small Temple": "Small Temple",
                    "Medium Temple": "Medium Temple",
                    "Outpost": "Outpost",
                    "Preaching Centre": "Preaching Centre",
                    "Extension Centre": "Extension Centre",

                    "State Rank": "State Rank",
                    "Zonal Ranking": "Zonal Ranking",
                    "Category Rank": "Category Rank",
                    "Total Points": "Total Points",
                    "Books Distributed": "Books Distributed",
                    "Events": "Events",
                    "Regular Update": "Regular Update",
                    "Bhadra Purnima": "Bhadra Purnima",
                    "Chaitanya Charitamrita": "Chaitanya Charitamrita",
                    "Gita Marathon": "Gita Marathon",
                    "All India Ranking": "All India Ranking",
                    "Zone Wise": "Zone Wise",
                    "Category Wise": "Category Wise",
                    "State Wise": "State Wise",
                    "Book Wise": "Book Wise",
                    "Filter By": "Filter By",
                    "Reset Filter": "Reset Filter",
                    "Ranking": "Ranking",
                    "Category": "Category",
                    "Particular": "Particular",
                    "Zone": "Zone",
                    "Score": "Score",

                    "Compare": "Compare",

                    "Dedicated to His Divine Grace A.C. Bhaktivedanta Swami Prabhupada - Founder Acharya of the International Society for Krishna Consciousness.": "Dedicated to His Divine Grace A.C. Bhaktivedanta Swami Prabhupada - Founder Acharya of the International Society for Krishna Consciousness.",
                    "All Rights Reserved.": "All Rights Reserved."
                    ,
                    "Submit Your Pledge": "Submit Your Pledge",
                    "Submit your pledge, update your pledge, and view your pledge history. A Regular Update refers to book distribution that occurs throughout the year, whereas a marathon refers to pledges undertaken during the marathon period.": "Submit your pledge, update your pledge, and view your pledge history. A Regular Update refers to book distribution that occurs throughout the year, whereas a marathon refers to pledges undertaken during the marathon period.",
                    "Your Current Pledge": "Your Current Pledge",
                    "View Historical Pledges": "View Historical Pledges",

                    "Edit your pledge here": "Edit your pledge here",
                    "Book": "Book",
                    "Points": "Points",
                    "Actual Pledge": "Actual Pledge",
                    "Count": "Count",
                    "Total Count": "Total Count",
                    "Total": "Total",
                    "9 Mahabig Books": "9 Mahabig Books",
                    "18 Mahabig Books": "18 Mahabig Books",
                    "Save": "Save",
                    "Pledged": "Pledged",
                    "Actuals": "Actuals",
                    "Are you sure you want to update the books count in": "Are you sure you want to update the books count in",
                    "Yes": "Yes",
                    "Cancel": "Cancel",
                    "Upload File": "Upload File",
                    "You are Uploading": "You are Uploading",
                    "Bulk Score": "Bulk Score",
                    "Close": "Close",
                    "Big Temple": "Big Temple",
                    "Small Temple": "Small Temple",
                    "Medium Temple": "Medium Temple",
                    "Outpost": "Outpost",
                    "Preaching Centre": "Preaching Centre",
                    "Extension Centre": "Extension Centre",
                    "Drop your file or click here to browse": "Drop your file or click here to browse",
                    "UPLOAD": "UPLOAD",
                    "South Zone": "South Zone",
                    "West Zone": "West Zone",
                    "East Zone": "East Zone",
                    "North Zone": "North Zone",
                    "Andaman & Nicobar Islands": "Andaman & Nicobar Islands",
                    "Andhra Pradesh": "Andhra Pradesh",
                    "Arunachal Pradesh": "Arunachal Pradesh",
                    "Assam": "Assam",
                    "Bihar": "Bihar",
                    "Chandigarh": "Chandigarh",
                    "Chhattisgarh": "Chhattisgarh",
                    "Dadra & Nagar Haveli": "Dadra & Nagar Haveli",
                    "Daman & Diu": "Daman & Diu",
                    "Delhi": "Delhi",
                    "Goa": "Goa",
                    "Gujarat": "Gujarat",
                    "Haryana": "Haryana",
                    "Himachal Pradesh": "Himachal Pradesh",
                    "Jammu & Kashmir": "Jammu & Kashmir",
                    "Jharkhand": "Jharkhand",
                    "Karnataka": "Karnataka",
                    "Kerala": "Kerala",
                    "Lakshadweep": "Lakshadweep",
                    "Madhya Pradesh": "Madhya Pradesh",
                    "Maharashtra": "Maharashtra",
                    "Manipur": "Manipur",
                    "Meghalaya": "Meghalaya",
                    "Mizoram": "Mizoram",
                    "Nagaland": "Nagaland",
                    "Odisha": "Odisha",
                    "Puducherry": "Puducherry",
                    "Punjab": "Punjab",
                    "Rajasthan": "Rajasthan",
                    "Sikkim": "Sikkim",
                    "Tamil Nadu": "Tamil Nadu",
                    "Tripura": "Tripura",
                    "Uttar Pradesh": "Uttar Pradesh",
                    "Uttarakhand": "Uttarakhand",
                    "West Bengal": "West Bengal",
                    "Telangana": "Telangana",
                    "Srimad Bhagavatam Sets": "Srimad Bhagavatam Sets",
                    "Chaitanya Charitamrita Sets": "Chaitanya Charitamrita Sets",
                    "Click Here to Download the Template": "Click Here to Download the Template",
                    "Instruction How to Upload the Data": "Instruction How to Upload the Data",
                    "Date fields": "Date fields",
                    "Showing": "Showing",
                    "The below graphs indicates state wise performance of book distribution.": "The below graphs indicates state wise performance of book distribution.",
                    "Darker color indicates higher book distribution, lighter color indicates lower book distribution.": "Darker color indicates higher book distribution, lighter color indicates lower book distribution.",
                    "In Book Name please add the name of the book with correct spelling.": "In Book Name please add the name of the book with correct spelling.",
                    "In Book Count please add the book distribution count.": "In Book Count please add the book distribution count.",
                    "In the Date fields please add the date for which you need to upload the data. The date format should be DD-MM-YYYY.": "In the Date fields please add the date for which you need to upload the data. The date format should be DD-MM-YYYY.",
                    "Upload file should be in the .xlsx format.": "Upload file should be in the .xlsx format.",
                    "Note: Once the file is uploaded, no corrections can be made.": "Note: Once the file is uploaded, no corrections can be made.",
                    "हिन्दी": "Hindi",
                    // Add more translations here...
                },
            },
            hi: {
                translation: {
                    "Dashboard": "डैशबोर्ड",
                    "Live Ranking": "तात्कालिक प्राप्त स्थान",
                    "Pledges": "संकल्प",
                    "Update Score": "अंक अपडेट करें",
                    "Resources": "संसाधन",
                    "Forum": "मंच",
                    "Contact Us": "संपर्क करें",
                    // "Overall Book Distribution": "समग्र वितरण",
                    "Videos": "वीडियो",
                    "Blogs": "ब्लॉग",
                    "Posters": "पोस्टर",
                    "Poster": "पोस्टर",
                    "Banner": "बैनर",
                    "Book Mark": "बुक मार्क",
                    "Pamphlet": "पुस्तिका",
                    "Quotes": "सुविचार",
                    "Our Forum": "हमारा मंच",
                    "View Questions": "प्रश्न देखें",
                    "Questions": "प्रश्न",
                    "Question": "सवाल",
                    "Search...": "खोजें...",
                    "Reset All": "सभी को पुनः तैयार करना",
                    "Ask Question": "प्रश्न पूछो",
                    "Newest": "नवीनतम",
                    "Sort By": "क्रमबद्ध करें",
                    "Search by Category": "श्रेणी के आधार पर खोजें",
                    "Trending": "ट्रेंडिंग",
                    "Likes": "पसंद है",
                    "Answers": "जवाब",
                    "Search by Zone": "ज़ोन के अनुसार खोजें",
                    "Search by Particular": "विशेष द्वारा खोजें",
                    "Back to Forum": "मंच पर वापस",
                    "Back": "वापस जाओ",
                    "Most Liked": "सर्वाधिक पसंद किया गया",
                    "Last Month": "पिछले महीने",
                    "Last Week": "पिछले सप्ताह",
                    "No Questions Found": "कोई प्रश्न नहीं मिला",
                    "Congregation Book Distribution": "मण्डली पुस्तक वितरण",
                    "Logout Account": "लॉग आउट करें",
                    "language": "भाषा",
                    "Welcome": "स्वागत है",
                    "Explore the dashboard for detailed insights and current progress in book distribution": "डैशबोर्ड का उपयोग करके विस्तृत जानकारी और पुस्तकों के वितरण में वर्तमान प्रगति की जानकारी प्राप्त करें।",
                    "You are on the": "आप",
                    "rank": "स्थान पर हैं",
                    "Rank": "स्थान पर हैं",
                    "State Name": "राज्य का नाम",
                    "Your score will please Srila Prabhupada.": "आपके अंक श्रील प्रभुपाद को प्रसन्नता प्रदान करेंगे।",
                    "Your Pledge": "आपके संकल्प",
                    "Your Book Points": "आपके पुस्तक के अंक",
                    "distributed": "वितरित",
                    "books": "पुस्तकें",
                    "Temple Performance Status": "मंदिर प्रदर्शन स्थिति",
                    "Download Sample": "सैंपल डाउनलोड करें",
                    "Pledge Covered": "संकल्प सम्पूर्णता की स्थिति",
                    "Your pledge completion status": "आपके संकल्प की स्थिति",
                    "Your Ranking": "आपके श्रेणी",
                    "Regular Distribution": "नियमित वितरण",
                    "Bhadra Purnima Marathon": "भाद्र पूर्णिमा मैराथन",
                    "Chaitanya Charitamrita Marathon": "चैतन्य चरितामृत मैराथन",
                    "Gita Marathon": "गीता मैराथन",
                    "All India Rank": "भारत में प्राप्त स्थान",
                    "State Rank": "राज्य में प्राप्त स्थान",
                    "Zonal Rank": "क्षेत्र में प्राप्त स्थान",
                    "Category Rank": "वर्ग में प्राप्त स्थान",
                    "Total Points": "कुल अंक",
                    "Book Wise Growth Chart": "पुस्तकों के अनुसार वृद्धि चार्ट",
                    "Year Wise Growth Chart": "वर्ष के अनुसार वृद्धि चार्ट",
                    "Book": "किताब",
                    "CC Set": "सीसी सेट",
                    "SB-Set": "एसबी-सेट",
                    // "Mahabig Books":
                    "Progress": "प्रगति",
                    "Books Count": "पुस्तकों की गिनती",
                    "Status": "स्थिति",
                    "Download": "डाउनलोड करे",
                    "Chaitanya Charitamrita Set": "चैतन्य चरितामृत सेट",
                    "Srimad Bhagavatam Set": "श्रीमद-भागवतम सेट",
                    "Mahabig Books": "महाबिग पुस्तकें",
                    "Big Books": "बड़ी पुस्तकें",
                    "Medium Books": "मध्यम पुस्तकें",
                    "Small Books": "छोटी पुस्तकें",
                    "Srila Prabhupada Lilamrita": "श्रील प्रभुपाद लीलामृत",
                    "7 Mahabig Books": "7 महाबिग पुस्तकें",
                    "S P Lilamrita": "एस पी लीलामृता",
                    "Magazines": "पत्रिकाएं",
                    "1-Year BTG-Eng": "1-वर्ष बीटीजी-अंग्रे.",
                    "1-Year BTG-Non-Eng": "1-वर्ष बीटीजी-गैर-अंग्रे.",
                    "Last Year": "पिछले वर्ष",
                    "Current Year": "वर्तमान वर्ष",
                    "1 Year BTG Subscription-1": "1 साल की बीटीजी सदस्यता-1",
                    "1 Year BTG Subscription-2": "1 साल की बीटीजी सदस्यता-2",
                    "Pages": "पृष्ठ",
                    "Are you sure you want to delete the question?": "क्या आप वाकई प्रश्न हटाना चाहते हैं?",
                    "500+ Pages": "500+ पृष्ठ",
                    "300-500 Pages": "300-500 पृष्ठ",
                    "150-300 Pages": "150-300 पृष्ठ",
                    "100-150 Pages": "100-150 पृष्ठ",
                    "10-50 Pages": "10-50 पृष्ठ",
                    "English Book": "अंग्रेजी पुस्तक",
                    "Non-English Book": "गैर-अंग्रेजी पुस्तक",
                    "Finished": "समाप्त",
                    "Unfinished": "अधूरा",
                    "In Progress": "प्रगति पर है",
                    "Top 10": "शीर्ष 10",
                    "Temples": "मंदिर",
                    "in": "में",
                    "Continue Reading...": "जारी रखें पढ़ रहे हैं...",
                    "Big Temple": "बड़ा मंदिर",
                    "Small Temple": "छोटा मंदिर",
                    "Medium Temple": "मध्यम मंदिर",
                    "Outpost": "आउटपोस्ट",
                    "Preaching Centre": "प्रचार केंद्र",
                    "Extension Centre": "विस्तार केंद्र",
                    "Points": "अंक",
                    "Your Stats": "आपके आँकड़े",
                    "All India Ranking": "सम्पूर्ण भारत में रैंकिंग",
                    // "State Rank": "राज्य रैंकिंग",
                    // "State Rank": "राज्य रैंक",
                    "Zonal Ranking": "जोनल रैंकिंग",
                    // "Category Rank": "श्रेणी रैंक",
                    "Total Points": "कुल अंक",
                    "Books Distributed": "वितरित की गईं पुस्तकें",
                    "India: Books Distributed": "भारत: वितरित पुस्तकें",

                    "Track real-time rankings and performance with Live Ranking Table, providing instant insights into book distribution and more.": "वर्तमान श्रेणी एवँ प्रदर्शन, वर्तमान श्रेणी तालिका के साथ पुस्तक वितरण एवँ अन्य तथ्यों की तत्काल स्थिति देखें।",
                    "Filter By": "फिल्टर करें",
                    "Events": "इवेंट्स",
                    "Regular Update": "नियमित अपडेट",
                    "Bhadra Purnima": "भद्र पूर्णिमा",
                    "Chaitanya Charitamrita": "चैतन्य चरितामृत",
                    "Gita Marathon": "गीता मैराथन",
                    "All India Ranking": "भारत में प्राप्त स्थान",
                    "Zone Wise": "क्षेत्र अनुसार",
                    "Category Wise": "श्रेणी अनुसार",
                    "State Wise": "राज्य अनुसार",
                    "Book Wise": "पुस्तक अनुसार",
                    "Reset Filter": "फ़िल्टर रीसेट करें",
                    "Compare": "तुलना करें",
                    "Ranking": "रैंकिंग",
                    "Category": "श्रेणी",
                    "Particular": "विशेष",
                    "Zone": "क्षेत्र",
                    "Score": "अंक",
                    "Zone Wise Total Points": "क्षेत्रानुसार कुल अंक",
                    "State Wise Points": "राज्य के अनुसार प्राप्त अंक",
                    "State-wise graphs visualize book distribution points specific to each state.": "राज्य-वार ग्राफ प्रत्येक राज्य के लिए पुस्तक वितरण अंक को दृश्य रूप में प्रस्तुत करते हैं।",
                    "Books Distributed by Category in each Zone": "प्रत्येक क्षेत्र में श्रेणी के अनुसार पुस्तकें वितरित की गईं",
                    "of": "का",
                    "Dedicated to His Divine Grace A.C. Bhaktivedanta Swami Prabhupada - Founder Acharya of the International Society for Krishna Consciousness.": "कृष्ण कृपामूर्ति ए.सी. भक्तिवेदांत स्वामी प्रभुपाद - अंतर्राष्ट्रीय कृष्ण भावनामृत संघ के संस्थापकाचार्य को समर्पित",
                    "All Rights Reserved.": "सर्वाधिकार सुरक्षित।",
                    "Submit Your Pledge": "अपनी संकल्प जमा करें",
                    "Submit your pledge, update your pledge, and view your pledge history. A Regular Update refers to book distribution that occurs throughout the year, whereas a marathon refers to pledges undertaken during the marathon period.": "अपना संकल्प यहाँ संपादित करें, अपने संकल्प को अपडेट करें, और अपने संकल्प का इतिहास देखें। नियमित अपडेट से तात्पर्य वर्ष भर होने वाले पुस्तक वितरण से है, जबकि मैराथन से तात्पर्य मैराथन अवधि के दौरान लिए गए संकल्प से है।",
                    "Your Current Pledge": "आपकी वर्तमान संकल्प ",
                    "View Historical Pledges": "ऐतिहासिक संकल्पों को देखें",
                    "Submit Your Pledge": "अपनी संकल्प अपडेट करें",
                    "Submit": "जमा करना",
                    "India's pledge completion status": "भारत के संकल्प पूर्णता की स्थिति",
                    "Edit your pledge here": "अपनी संकल्प यहाँ संपादित करें",
                    "Book": "पुस्तक",
                    "Points": "अंक",
                    "Actual Pledge": "वास्तविक संकल्प",
                    "Count": "गिनती",
                    "Total Count": "कुल गिनती",
                    "9 Mahabig Books": "9 महाबिग पुस्तकें",
                    "18 Mahabig Books": "18 महाबिग पुस्तकें",
                    "Total": "कुल",
                    "Save": "सेव करें",
                    "Update Your Points": "अपने अंक अपडेट करें",
                    "Submit your book distribution points, update your points and view your points history.": "आपके पुस्तक वितरण अंक प्रस्तुत करें, आपके अंक अपडेट करें और अपने अंको का इतिहास देखें।",
                    "Your Performance Status": "आपकी प्रदर्शन स्थिति",
                    "Bulk Upload": "बल्क अपलोड",
                    "View Historical Points": "ऐतिहासिक अंक देखें",
                    "Update Your points": "अपने अंक अपडेट करें",
                    "Pledged": "संकल्प",
                    "Actuals": "वास्तविक",
                    "No. Of Books": "पुस्तकों की संख्या",
                    "Edit your points here": "यहां अपने अंक संपादित करें",
                    "Select Date": "तारीख चुनें",
                    "Modify": "संशोधित करें",
                    "Are you sure you want to update the books count in": "क्या आप निश्चित हैं कि आप किताबों की गिनती को अपडेट करना चाहते हैं",
                    "Yes": "हाँ",
                    "Cancel": "रद्द करें",
                    "Need to get in touch with us? Either fill out the form with your inquiry or kindly contact us at": "हमसे संपर्क करना चाहते हैं? कृपया अपना प्रश्न फ़ॉर्म में भरें या हमसे संपर्क करें",
                    "or call on": "या हमें इस नंबर पर कॉल करें",
                    "ISKCON Logo": "इस्कॉन लोगो",
                    "Need Help?": "सहायता चाहिए?",
                    "Subject": "विषय",
                    "Your Message": "आपका संदेश",
                    "Type here": "यहां टाइप करें",
                    "Type your message": "यहाँ अपना संदेश टाइप करें",
                    "Send Message": "संदेश भेजें",
                    "Send": "भेजें",
                    "Sevak Head": "सेवक मुखिया",
                    "Upload File": "फाइल अपलोड करें",
                    "Please enter the subject": "कृपया विषय दर्ज करें",
                    "Please enter the message": "कृपया संदेश दर्ज करें",
                    "Uploaded File": "अपलोड की गई फाइल",
                    "Click to Upload": "अपलोड करने के लिए क्लिक करें",
                    "or drag and drop": "या फाइल खींचकर छोड़ें",
                    "Max. File size: 2 MB": "अधिकतम फाइल आकार: 2 एमबी",
                    "File size exceeds 2MB. Please upload a smaller file.": "फाइल का आकार 2MB से अधिक है। कृपया एक छोटी फाइल अपलोड करें।",
                    "Upload File": "फ़ाइल अपलोड करें",
                    "You are Uploading": "आप अपलोड कर रहे हैं",
                    "Bulk Score": "बल्क अंक",
                    "Close": "बंद करें",
                    "Drop your file or click here to browse": "अपनी फ़ाइल ड्रॉप करें या ब्राउज़ करने के लिए यहां क्लिक करें",
                    "UPLOAD": "अपलोड",
                    "Showing": "दिखाएं",
                    "Date fields": "दिनांक फ़ील्ड",
                    "Click Here to Download the Template": "टेम्पलेट डाउनलोड करने के लिए यहाँ क्लिक करें",
                    "Instructions How To Upload The Data": "डेटा अपलोड करने के निर्देश",
                    "In Book Name please add the name of the book with correct spelling.": "बुक नाम में कृपया सही स्पेलिंग के साथ बुक का नाम जोड़ें।",
                    "In Book Count please add the book distribution count.": "बुक काउंट में कृपया बुक वितरण की गणना जोड़ें।",
                    "In the Date Fields please add the date for which you need to upload the data. The date format should be DD-MM-YYYY.": "तारीख़ के फ़ील्ड में कृपया उस तारीख़ को जोड़ें जिसके लिए आपको डेटा अपलोड करना है। तारीख़ का प्रारूप DD-MM-YYYY होना चाहिए।",
                    "Upload file should be in the .xlsx format.": "अपलोड की गई फ़ाइल .xlsx प्रारूप में होनी चाहिए।",
                    "Note: Once the file is uploaded, no corrections can be made.": "टिप्पणी: एक बार फ़ाइल अपलोड हो जाने के बाद, कोई संशोधन नहीं किया जा सकता।",
                    "Compare With Another Temple": "दूसरे मंदिर से तुलना करें",
                    "Select Temple": "मंदिर चुनें",
                    "1 Year BTG Subscription-English": "1 वर्ष बीटीजी सदस्यता-अंग्रेज़ी",
                    "1 Year BTG Subscription-Non English": "1 वर्ष बीटीजी सदस्यता-गैर-अंग्रेज़ी",
                    "Zone Ranking": "क्षेत्र में प्राप्त स्थान",
                    "Category Ranking": "वर्ग में प्राप्त स्थान",
                    "State Ranking": "राज्य में प्राप्त स्थान",
                    "Hare Krishna": "हरे कृष्ण",
                    "Logout Account": "लॉगआउट खाता",
                    "My Profile": "मेरा प्रोफ़ाइल",
                    "Notifications": "सूचनाएं",
                    "Name": "नाम",
                    "Phone": "फ़ोन",
                    "Email": "ईमेल",
                    "Role": "भूमिका",
                    "Temple": "मंदिर",
                    // "Zone": "ज़ोन",
                    "State": "राज्य",
                    "Note": "टिप्पणी",
                    "The below graphs indicates state wise performance of book distribution,": "नीचे दिए गए ग्राफ़ पुस्तक वितरण का राज्यानुसार प्रदर्शन को दर्शाते हैं।",
                    "Darker color indicates higher book distribution, lighter color indicates lower book distribution.": "गहरा रंग अधिक पुस्तक वितरण को दर्शाता है, हल्का रंग कम पुस्तक वितरण को दर्शाता है।",
                    "Step": "स्टेप",
                    "Download Excel": "एक्सेल डाउनलोड करें",
                    "Enter your name": "अपना नाम दर्ज करें",
                    "Enter your phone number": "अपना फ़ोन नंबर दर्ज करें",
                    "Enter your email": "अपना ईमेल दर्ज करें",
                    "Enter your role": "अपनी भूमिका दर्ज करें",
                    "Enter your temple": "अपना मंदिर दर्ज करें",
                    "Enter your zone": "अपना ज़ोन दर्ज करें",
                    "Enter your state": "अपना राज्य दर्ज करें",
                    "India: Pledge": "भारत का संकल्प",
                    "India: Book Points": "भारत के पुस्तक अंक",
                    "Your Score": "आपका अंक",
                    "India's Score": "भारत का अंक",
                    "Download": "डाउनलोड",
                    "Sr.No": "क्रमांक",
                    "India: Book Distributed": "भारत का पुस्तक वितरित",
                    "Book Name": "पुस्तक का नाम",
                    "Event Name": "कार्यक्रम का नाम",
                    "No. of Books": "पुस्तकों की संख्या",
                    "Updated By": "अप्डेटेड बाय",
                    "Updated On": "अप्डेटेड तारीख ",
                    "Updated For": "अप्डेटेड फॉर",
                    "All Your Historical": "आपके सभी ऐतिहासिक",
                    "View Your Historical Points": "अपने ऐतिहासिक अंक देखें",
                    "Sankirtan Leader": "संकीर्तन प्रमुख",
                    "No History Found": "कोई इतिहास नहीं मिला",
                    "View Your Historical Pledges": "अपने ऐतिहासिक संकल्प को देखें",
                    "Hide replies": "उत्तर छुपाएँ",
                    "View replies": "उत्तर देखें",
                    "Answer": "उत्तर",
                    "Blog And Articles": "ब्लॉग और लेख",
                    "Read More": "अधिक पढ़ें",
                    "Categories": "श्रेणियाँ",
                    "Forum details": "फोरम विवरण",
                    "Reply": "जवाब",
                    "Write a comment": "एक टिप्पणी लिखें",
                    "For Prabhupada's Pleasure": "प्रभुपाद की प्रसन्नता के लिए",
                    "write your comment here": "यहाँ अपनी टिप्पणी लिखें",
                    "Post Comments": "टिप्पणियाँ पोस्ट करें",
                    "Comments": "टिप्पणियाँ",
                    "Comment": "टिप्पणी",
                    "Enter question": "अपना प्रश्न दर्ज करें",
                    "Popular": "लोकप्रिय",
                    "Your Answer": "आपका उत्तर",
                    "All Categories": "सभी श्रेणियाँ",
                    "Srimad Bhagavatam": "श्रीमद भगवतम",
                    "Gita Marathon": "गीता मैराथन",
                    "CC Marathon": "सीसी मैराथन",
                    "Book Pricing": "पुस्तक मूल्य निर्धारण",
                    "History of Book Distribution": "पुस्तक वितरण का इतिहास",
                    "Monthly Sankirtan Festival": "मासिक संकीर्तन महोत्सव",
                    "Vidya Daan": "विद्या दान",
                    "Corporate Book Distribution": "कॉर्पोरेट पुस्तक वितरण",
                    "Success Stories": "सफलता की कहानियाँ",
                    "Prabhupada Distributing Books": "प्रभुपाद पुस्तकों का वितरण",
                    "For Prabhupada's Pleasure": "प्रभुपाद की प्रसन्नता के लिए",
                    "Others": "अन्य",
                    "Search...": "खोजें...",
                    "Quotes": "विचार",
                    "Big Temple": "बड़ा मंदिर",
                    "Small Temple": "छोटा मंदिर",
                    "Medium Temple": "मध्यम मंदिर",
                    "Outpost": "आउटपोस्ट",
                    "Preaching Centre": "प्रचार केंद्र",
                    "Extension Centre": "विस्तार केंद्र",
                    "South Zone": "दक्षिण क्षेत्र",
                    "West Zone": "पश्चिम क्षेत्र",
                    "East Zone": "पूर्व क्षेत्र",
                    "North Zone": "उत्तर क्षेत्र",
                    "Andaman & Nicobar Islands": "अंडमान और निकोबार द्वीपसमूह",
                    "Andaman & Nicobar Island": "अंडमान और निकोबार द्वीपसमूह",

                    "Andhra Pradesh": "आंध्र प्रदेश",
                    "Arunachal Pradesh": "अरुणाचल प्रदेश",
                    "Assam": "असम",
                    "Bihar": "बिहार",
                    "Chandigarh": "चंडीगढ़",
                    "Chhattisgarh": "छत्तीसगढ़",
                    "Dadra & Nagar Haveli": "दादरा और नगर हवेली",
                    "Daman & Diu": "दमन और दीव",
                    "Delhi": "दिल्ली",
                    "Goa": "गोवा",
                    "Gujarat": "गुजरात",
                    "Haryana": "हरियाणा",
                    "Himachal Pradesh": "हिमाचल प्रदेश",
                    "Jammu & Kashmir": "जम्मू और कश्मीर",
                    "Jammu and Kashmir": "जम्मू और कश्मीर",
                    "Marathon": "मैराथन",
                    "Jharkhand": "झारखंड",
                    "Karnataka": "कर्नाटक",
                    "Kerala": "केरल",
                    "Lakshadweep": "लक्षद्वीप",
                    "Madhya Pradesh": "मध्य प्रदेश",
                    "Maharashtra": "महाराष्ट्र",
                    "Manipur": "मणिपुर",
                    "Meghalaya": "मेघालय",
                    "Mizoram": "मिज़ोरम",
                    "Nagaland": "नागालैंड",
                    "Odisha": "ओडिशा",
                    "Puducherry": "पुडुचेरी",
                    "Punjab": "पंजाब",
                    "Rajasthan": "राजस्थान",
                    "Sikkim": "सिक्किम",
                    "Tamil Nadu": "तमिलनाडु",
                    "Tripura": "त्रिपुरा",
                    "Uttar Pradesh": "उत्तर प्रदेश",
                    "Uttarakhand": "उत्तराखंड",
                    "West Bengal": "पश्चिम बंगाल",
                    "Telangana": "तेलंगाना",
                    "Srimad Bhagavatam Set": "श्रीमद्भागवातम सेट",
                    "Chaitanya Charitamrita Set": "चैतन्य चरितामृत सेट",
                    "Select Category": "श्रेणी चुनें",
                    "Select Zone": "क्षेत्र चुनें",
                    "Select State": "राज्य चुनें",
                    "Select Zone": "क्षेत्र चुनें",
                    "1st": "पहले",
                    "2nd": "- दूसरे",
                    "3rd": " तीसरे",
                    "4th": "चौथे",
                    "5th": "पांचवेँ",
                    "6th": "छठे",
                    "7th": "सातवेँ",
                    "8th": "आठवेँ",
                    "9th": "नौवेँ",
                    "10th": "दसवेँ",
                    "11th": "ग्यारहवेँ",
                    "12th": "बारहवेँ",
                    "13th": "तेरहवेँ",
                    "14th": "चौदहवेँ",
                    "15th": "पंद्रहवेँ",
                    "16th": "सोलहवेँ",
                    "17th": "सत्रहवेँ",
                    "18th": "अठारहवेँ",
                    "19th": "उन्नीसवेँ",
                    "20th": "बीसवेँ",
                    "21st": "इक्कीसवेँ",
                    "22nd": "बाईसवेँ",
                    "23rd": "तेईसवेँ",
                    "24th": "चौबीसवेँ",
                    "25th": "पच्चीसवेँ",
                    "26th": "छब्बीसवेँ",
                    "27th": "सत्ताईसवेँ",
                    "28th": "अठाईसवेँ",
                    "29th": "उन्तीसवेँ",
                    "30th": "तीसवेँ",
                    "31st": " इकत्तीसवें",
                    "32nd": "बत्तीसवें",
                    "33rd": "तैंतीसवें",
                    "34th": "चौंतीसवें",
                    "35th": "पैंतीसवें",
                    "36th": "छत्तीसवें",
                    "37th": "सैंतीसवें",
                    "38th": "अड़तीसवें",
                    "39th": "उनतालीसवें ",
                    "40th": "चालीसवें",
                    "41st": "इकतालीसवें",
                    "42nd": "बयालीसवें",
                    "43rd": "तैतालीसवें",
                    "44th": "चौवालीसवें",
                    "45th": "पैंतालीसवें",
                    "46th": "छियालीसवें",
                    "47th": "सैंतालीसवें",
                    "48th": "अड़तालीसवें",
                    "49th": "उनचासवें",
                    "50th": "पचासवें",
                    "51st": "इक्यावनवें",
                    "52nd": "बावनवें",
                    "53rd": "तिरपनवें",
                    "54th": "चौवनवें",
                    "55th": "पचपनवें",
                    "56th": "छप्पनवें",
                    "57th": "सतावनवें",
                    "58th": "अट्ठावनवें",
                    "59th": "उनसठवें",
                    "60th": "साठवें",
                    "61st": "इकसठवें",
                    "62nd": "बाहसठवें",
                    "63rd": "तिरसठवें",
                    "64th": "चौंसठवें",
                    "65th": "पैंसठवें",
                    "66th": "छियासठवें",
                    "67th": "सड़सठवें",
                    "68th": "अड़सठवें",
                    "69th": "उनहत्तरवें",
                    "70th": "सत्तरवें",
                    "71st": "इकहत्तरवें",
                    "72nd": "बहत्तरवें",
                    "73rd": "तिहत्तरवें",
                    "74th": "चौहत्तरवें",
                    "75th": "पचहत्तरवें",
                    "76th": "छिहत्तरवें",
                    "77th": "सतहत्तरवें",
                    "78th": "अठहत्तरवें",
                    "79th": "उनासीवें",
                    "80th": "अस्सीवें",
                    "81st": "इक्यासीवें",
                    "82nd": "बयासीवें",
                    "83rd": "तिरासीवें",
                    "84th": "चौरासीवें",
                    "85th": "पच्चासीवें",
                    "86th": "छियासीवें",
                    "87th": "सत्त्तासीवें",
                    "88th": "अट्ठासीवें",
                    "89th": "नवासीवें",
                    "90th": "नब्बेवें",
                    "91st": "इक्यानवें",
                    "92nd": "बानवें",
                    "93rd": "तिरानवें",
                    "94th": "चौरानवें",
                    "95th": "पंचानवें",
                    "96th": "छियानवें",
                    "97th": "सत्तानवें",
                    "98th": "अट्ठानवें",
                    "99th": "निन्यानवें",
                    "100th": "सौवें",
                    "101st": "एक सौ एकवें",
                    "102nd": "एक सौ दो",
                    "103rd": "एक सौ तीन",
                    "104th": "एक सौ चार",
                    "105th": "एक सौ पांचवें",
                    "106th": "एक सौ छठे ",
                    "107th": "एक सौ सातवें",
                    "108th": "एक सौ आठवें",
                    "109th": "एक सौ नौवें",
                    "110th": "एक सौ दसवें",
                    "111th": "एक सौ ग्यारहवें",
                    "112th": "एक सौ बारहवें",
                    "113th": "एक सौ तेरहवें",
                    "114th": "एक सौ चौदहवें",
                    "115th": "एक सौ पंद्रहवें",
                    "116th": "एक सौ सोलहवें",
                    "117th": "एक सौ सत्रहवें",
                    "118th": "एक सौ अठारहवें",
                    "119th": "एक सौ उन्नीसवें",
                    "120th": "एक सौ बीसवें",
                    "121st": "एक सौ इक्कीसवें",
                    "122nd": "एक सौ बाईसवें",
                    "123rd": "एक सौ तेईसवें",
                    "124th": "एक सौ चौबीसवें",
                    "125th": "एक सौ पच्चीसवें",
                    "126th": "एक सौ छब्बीसवें",
                    "127th": "एक सौ सत्ताईसवें",
                    "128th": "एक सौ अठाईसवें",
                    "129th": "एक सौ उन्नतीसवें",
                    "130th": "एक सौ तीसवें",
                    "131st": "एक सौ इकतीसवें",
                    "132nd": "एक सौ बत्तीसवें",
                    "133rd": "एक सौ तैंतीसवें",
                    "134th": "एक सौ चौंतीसवें",
                    "135th": "एक सौ पैंतीसवें",
                    "136th": "एक सौ छत्तीसवें",
                    "137th": "एक सौ सैंतीसवें",
                    "138th": "एक सौ अड़तीसवें",
                    "139th": "एक सौ उनचालीसवें",
                    "140th": "एक सौ चालीसवें",
                    "141st": "एक सौ इकतालीसवें",
                    "142nd": "एक सौ बयालीसवें",
                    "143rd": "एक सौ तैतालीसवें",
                    "144th": "एक सौ चौंतालीसवें",
                    "145th": "एक सौ पैंतालीसवें",
                    "146th": "एक सौ छियालीसवें",
                    "147th": "एक सौ सैंतालीसवें",
                    "148th": "एक सौ अड़तालीसवें",
                    "149th": "एक सौ उनचासवें",
                    "150th": "एक सौ पचासवें",
                    "151st": "एक सौ इक्यावनवें",
                    "152nd": "एक सौ बावनवें",
                    "153rd": "एक सौ तिरपनवें",
                    "154th": "एक सौ चौवनवें",
                    "155th": "एक सौ पचपनवें",
                    "156th": "एक सौ छप्पनवें",
                    "157th": "एक सौ सतावनवें",
                    "158th": "एक सौ अट्ठावनवें",
                    "159th": "एक सौ उनसठवें",
                    "160th": "एक सौ साठवें",
                    "161st": "एक सौ इकसठवें",
                    "162nd": "एक सौ बाहसठवें",
                    "163rd": "एक सौ तिरसठवें",
                    "164th": "एक सौ चौंसठवें",
                    "165th": "एक सौ पैंसठवें",
                    "166th": "एक सौ छियासठवें",
                    "167th": "एक सौ सड़सठवें",
                    "168th": "एक सौ अड़सठवें",
                    "169th": "एक सौ उनहत्तरवें",
                    "170th": "एक सौ सत्तरवें",
                    "171st": "एक सौ इकहत्तरवें",
                    "172nd": "एक सौ बहत्तरवें",
                    "173rd": "एक सौ तिहत्तरवें",
                    "174th": "एक सौ चौहत्तरवें",
                    "175th": "एक सौ पचहत्तरवें",
                    "176th": "एक सौ छिहत्तरवें",
                    "177th": "एक सौ सतहत्तरवें",
                    "178th": "एक सौ अठहत्तरवें",
                    "179th": "एक सौ उनासीवें",
                    "180th": "एक सौ अस्सीवें",
                    "181st": "एक सौ इक्यासीवें",
                    "182nd": "एक सौ बयासीवें",
                    "183rd": "एक सौ तिरासीवें",
                    "184th": "एक सौ चौरासीवें",
                    "185th": "एक सौ पच्चासीवें",
                    "186th": "एक सौ छियासीवें",
                    "187th": "एक सौ सत्तासीवें",
                    "188th": "एक सौ अठासीवें",
                    "189th": "एक सौ नवासीवें",
                    "190th": "एक सौ नब्बेवें",
                    "191st": "एक सौ इक्यानवें",
                    "192nd": "एक सौ बानवें",
                    "193rd": "एक सौ तिरानवें",
                    "194th": "एक सौ चौरानवें",
                    "195th": "एक सौ पंचानवें",
                    "196th": "एक सौ छियानवें",
                    "197th": "एक सौ सत्तानवें",
                    "198th": "एक सौ अट्ठानवें",
                    "199th": "एक सौ निन्यानवें",
                    "200th": "दो सौवेँ",
                    "201st": "दो सौ एक",
                    "202nd": "दो सौ दो",
                    "203rd": "दो सौ तीन",
                    "204th": "दो सौ चार",
                    "205th": "दो सौ पांच",
                    "206th": "दो सौ छठा",
                    "207th": "दो सौ सात",
                    "208th": "दो सौ आठ",
                    "209th": "दो सौ नौ",
                    "210th": "दो सौ दसवें",
                    "211th": "दो सौ ग्यारहवें",
                    "212th": "दो सौ बारहवें",
                    "213rd": "दो सौ तेरहवें",
                    "214th": "दो सौ चौदहवें",
                    "215th": "दो सौ पंद्रहवें",
                    "216th": "दो सौ सोलहवें",
                    "217th": "दो सौ सत्रहवें",
                    "218th": "दो सौ अठारहवें",
                    "219th": "दो सौ उन्नीसवें",
                    "220th": "दो सौ बीसवें",
                    "221st": "दो सौ इक्कीसवें",
                    "222nd": "दो सौ बाईसवें",
                    "223rd": "दो सौ तेईसवें",
                    "224th": "दो सौ चौबीसवें",
                    "225th": "दो सौ पच्चीसवें",
                    "226th": "दो सौ छब्बीसवें",
                    "227th": "दो सौ सत्ताईसवें",
                    "228th": "दो सौ अठाईसवें",
                    "229th": "दो सौ उन्नतीसवें",
                    "230th": "दो सौ तीसवें",
                    "231st": "दो सौ इकतीसवें",
                    "232nd": "दो सौ बत्तीसवां",
                    "233rd": "दो सौ तैंतीसवें",
                    "234th": "दो सौ चौंतीसवें",
                    "235th": "दो सौ पैंतीसवें",
                    "236th": "दो सौ छत्तीसवें",
                    "237th": "दो सौ सैंतीसवें",
                    "238th": "दो सौ अड़तीसवें",
                    "239th": "दो सौ उनचालीसवें",
                    "240th": "दो सौ चालीसवें",
                    "241st": "दो सौ इकतालीसवें",
                    "242nd": "दो सौ बयालीसवें",
                    "243rd": "दो सौ तैतालीसवें",
                    "244th": "दो सौ चौंतालीसवें",
                    "245th": "दो सौ पैंतालीसवें",
                    "246th": "दो सौ छियालीसवें",
                    "247th": "दो सौ सैंतालीसवें",
                    "248th": "दो सौ अठतालीसवें",
                    "249th": "दो सौ उनचासवें",
                    "250th": "दो सौ पचासवें",
                    "251st": "दो सौ इक्यावनवें",
                    "252nd": "दो सौ बावनवें",
                    "253rd": "दो सौ तिरपनवें",
                    "254th": "दो सौ चववनवें",
                    "255th": "दो सौ पचपनवें",
                    "256th": "दो सौ छप्पनवें",
                    "257th": "दो सौ सतावनवें",
                    "258th": "दो सौ अट्ठावनवें",
                    "259th": "दो सौ उनसठवें",
                    "260th": "दो सौ साठवें",
                    "261st": "दो सौ इकसठवें",
                    "262nd": "दो सौ बाहसठवें",
                    "263rd": "दो सौ तिरसठवें",
                    "264th": "दो सौ चौंसठवें",
                    "265th": "दो सौ पैंसठवें",
                    "266th": "दो सौ छियासठवें",
                    "267th": "दो सौ सड़सठवें",
                    "268th": "दो सौ अड़सठवें",
                    "269th": "दो सौ उनहत्तरवें",
                    "270th": "दो सौ सत्तरवें",
                    "271st": "दो सौ इकहत्तरवें",
                    "272nd": "दो सौ बहत्तरवें",
                    "273rd": "दो सौ तिहत्तरवें",
                    "274th": "दो सौ चौहत्तरवें",
                    "275th": "दो सौ पचहत्तरवें",
                    "276th": "दो सौ छिहत्तरवें",
                    "277th": "दो सौ सतहत्तरवें",
                    "278th": "दो सौ अठहत्तरवें",
                    "279th": "दो सौ उनासीवें",
                    "280th": "दो सौ अस्सीवें",
                    "281st": "दो सौ इक्यासीवें",
                    "282nd": "दो सौ बासीवें",
                    "283rd": "दो सौ तिरासीवें",
                    "284th": "दो सौ चौरासीवें",
                    "285th": "दो सौ पच्चासीवें",
                    "286th": "दो सौ छियासीवें",
                    "287th": "दो सौ सत्तासीवें",
                    "288th": "दो सौ अठासीवें",
                    "289th": "दो सौ नवासीवें",
                    "290th": "दो सौ नब्बेवें",
                    "291st": "दो सौ इक्यानवें",
                    "292nd": "दो सौ बानवें",
                    "293rd": "दो सौ तिरानवें",
                    "294th": "दो सौ चौरानवें",
                    "295th": "दो सौ पंच्चानवें",
                    "296th": "दो सौ छियानवें",
                    "297th": "दो सौ सत्तानवें",
                    "298th": "दो सौ अठानवें",
                    "299th": "दो सौ निन्यानवें",
                    "300th": "तीन सौवें",
                    "301st": "तीन सौ एक",
                    "302nd": "तीन सौ दो",
                    "303rd": "तीन सौ तीन",
                    "304th": "तीन सौ चार",
                    "305th": "तीन सौ पांच",
                    "306th": "तीन सौ छ",
                    "307th": "तीन सौ सातवें",
                    "308th": "तीन सौ आठवें",
                    "309th": "तीन सौ नौवें",
                    "310th": "तीन सौ दसवें",
                    "311th": "तीन सौ ग्यारहवें",
                    "312th": "तीन सौ बारहवें",
                    "313rd": "तीन सौ तेरहवें",
                    "314th": "तीन सौ चौदहवें",
                    "315th": "तीन सौ पंद्रहवें",
                    "316th": "तीन सौ सोलहवें",
                    "317th": "तीन सौ सत्रहवें",
                    "318th": "तीन सौ अठारहवें",
                    "319th": "तीन सौ उन्नीसवें",
                    "320th": "तीन सौ बीसवें",
                    "321st": "तीन सौ इक्कीसवें",
                    "322nd": "तीन सौ बाईसवें",
                    "323rd": "तीन सौ तेईसवें",
                    "324th": "तीन सौ चौबीसवें",
                    "325th": "तीन सौ पच्चीसवें",
                    "326th": "तीन सौ छब्बीसवें",
                    "327th": "तीन सौ सत्ताईसवें",
                    "328th": "तीन सौ अठाईसवें",
                    "329th": "तीन सौ उन्नतीसवें",
                    "Load More": "और लोड करें",
                    "Overall Book Distribution": "समग्र पुस्तक वितरण",
                    "Total Score": "कुल अंक",
                    "Year Wise Stats": "वर्ष वाइज आँकड़े",
                    "Today's Update": "आज का अपडेट",
                    "Event Wise Dashboard": "कार्यक्रमानुसार डैशबोर्ड",
                    "Comparison": "तुलना",
                    "Vs": "बनाम",
                    "Book Count": "पुस्तक गणना",
                    "Upload file": "फ़ाइल अपलोड करें",
                    "Upload": "अपलोड करें",
                    "Your Temples": "आपके मंदिर",
                    "Temple Performance": "मंदिर प्रदर्शन",
                    "State Wise Rank": "राज्य के अनुसार प्राप्त अंक",
                    "Temple stats": "मंदिर के आँकड़े",
                    "Select and upload the file of your choice": "अपनी पसंद की फ़ाइल चुनें और अपलोड करें",
                    "Choose a file or drag & drop it here": "कोई फ़ाइल चुनें या उसे यहां खींचें और छोड़ें",
                    "Excel formats: XLSX, XLS": "एक्सेल प्रारूप: XLSX, XLS",
                    "Browse File": "फाइल खोजो",
                    "Year Wise Book Stats": "वर्ष वाइज पुस्तक आँकड़े",
                    "Book Distribution": "पुस्तक वितरण",
                    "Temple Wise Stats": "मंदिर के अनुसार आँकड़े",
                    "Stats of": "आँकड़े का",
                    "Year": "वर्ष",
                    "Post": "डाक",
                    'Sankirtan Leader': 'संकीर्तन प्रभारी',
                    'GBC (Governing Body Commission)': 'जीबीसी (शासी निकाय आयोग)',
                    'Temple President': 'मंदिर अध्यक्ष',
                    'General Manager': 'महाप्रबंधक',
                    'Regional Secretary/Zonal Secretary': 'क्षेत्रीय सचिव/जोनल सचिव',
                    'Chairman of TMC': 'टीएमसी के अध्यक्ष',
                    'Regional Secretary Assistant': 'क्षेत्रीय सचिव सहायक',
                    'Outpost Manager': 'चौकी प्रबंधक',
                    "The Gita Marathon event is completed. You cannot submit scores for the marathon period. Please change the date.": "गीता मैराथन कार्यक्रम संपन्न हो गया। आप मैराथन अवधि के लिए स्कोर प्रस्तुत नहीं कर सकते। कृपया तारीख बदलें.",
                    "You cannot upload the score for a future date. Please change the date.": "आप भविष्य की तारीख के लिए स्कोर अपलोड नहीं कर सकते। कृपया तारीख बदलें.",
                    "India's Score": "भारत के अंक",
                    "Failed to Submit Score": "स्कोर सबमिट करने में विफल",
                    "Score Submitted Successfully": "स्कोर सफलतापूर्वक सबमिट किया गया",
                    "Please select a date to add book count.": "कृपया पुस्तक संख्या जोड़ने के लिए एक तिथि चुनें",
                    "The gita marathon has been ended for the selected date. Please change the date to update the book count.": "गीता मैराथन चयनित तिथि के लिए समाप्त हो गई है। कृपया पुस्तक संख्या अद्यतन करने के लिए तिथि बदलें",
                    "Please update your book count in bhadra purnima marathon.": "कृपया भाद्र पूर्णिमा मैराथन में अपनी बुक संख्या अपडेट करें",
                    "Please update your book count in chaitanya charitamrita marathon.": "कृपया चैतन्य चरितामृत मैराथन में अपनी पुस्तक संख्या अद्यतन करें",
                    "The bhadra purnima marathon has been ended for the selected date. Please change the date to update srimad bhagavatam set count.": "भाद्र पूर्णिमा मैराथन चयनित तिथि के लिए समाप्त हो गई है। कृपया श्रीमद्भागवत सेट संख्या को अद्यतन करने के लिए तिथि बदलें।",
                    "The chaitanya charitamrita marathon has been ended for the selected date. Please change the date to update chaitanya charitamrita set count.": "चैतन्य चरित्रामृत मैराथन चयनित तिथि के लिए समाप्त कर दी गई है। चैतन्य चरितामृत सेट गिनती को अद्यतन करने के लिए कृपया तिथि बदलें",
                    "marathon is over for the selected date. Please change the date to update the book count.": "चयनित तारीख के लिए मरेथन समाप्त हो चुका है। कृपया तारीख बदलें और पुस्तक गिनती अपडेट करें।",
                    "Please update your SB Set books count in the Bhadra Purnima Marathon.": "कृपया भाद्र पूर्णिमा मैराथन में अपनी एसबी सेट पुस्तकों की संख्या अपडेट करें।",
                    "Please update your CC Set books count in the Chaitanya Charitamrita Marathon.": "कृपया चैतन्य चरितामृत मैराथन में अपनी सीसी सेट पुस्तकों की संख्या अपडेट करें।",
                    "The Bhadra Purnima event is completed. You cannot submit SB set scores for the marathon period. Please change the date.": "भाद्र पूर्णिमा का आयोजन पूर्ण हो गया है। आप मैराथन अवधि के लिए एसबी सेट स्कोर प्रस्तुत नहीं कर सकते। कृपया तारीख बदलें.",
                    "The Chaitanya Charitamrita event is completed. You cannot submit CC set scores for the marathon period. Please change the date.": "चैतन्य चरितामृत प्रसंग सम्पन्न हुआ। आप मैराथन अवधि के लिए सीसी सेट स्कोर प्रस्तुत नहीं कर सकते। कृपया तारीख बदलें.",
                    "December Bhagavad Gita Marathon refers to book distribution between 15th Nov to 15th Jan": "दिसंबर माह में श्रीमद् भगवद् गीता मैराथन 15 नवंबर से 15 जनवरी के बीच पुस्तक वितरण को संदर्भित करता है",
                    "Chaitanya Charitamrita Marathon refers to CC set distribution from 15th Jan till Gaura Purnima": "श्री चैतन्य चरितामृत मैराथन 15 जनवरी से गौर पूर्णिमा तक वितरित चैतन्य चरितामृत को संदर्भित करता है",
                    "Bhadra Purnima Marathon refers to SB set distribution from Gaura Purnima till Bhadra Purnima": "भाद्र पूर्णिमा मैराथन गौर पूर्णिमा से भाद्र पूर्णिमा तक श्रीमद्भागवत सेट वितरण को संदर्भित करता है",
                    "Overall Book Distribution refers to year-round distribution and includes all marathons": "समग्र पुस्तक वितरण का तात्पर्य साल भर के वितरण से है और इसमें सभी मैराथन सम्मिलित हैं",
                    "Select any temple to track the ranking and book wise progress of the temple.": "मंदिर की रैंकिंग और पुस्तकवार प्रगति को ट्रैक करने के लिए किसी भी मंदिर का चयन करें",
                    "Install App": "ऐप इंस्टॉल करें",
                    "This page provides a comprehensive overview of performance metrics for temples across India, categorized by events, zones, and states.": "यह पृष्ठ पूरे भारत में मंदिरों के प्रदर्शन मेट्रिक्स का एक व्यापक अवलोकन प्रदान करता है, जो घटनाओं, क्षेत्रों और राज्यों द्वारा वर्गीकृत है।",
                    "Hare Krishna Hare Krishna, Krishna Krishna Hare Hare | Hare Rama Hare Rama, Rama Rama Hare Hare": "हरे कृष्ण हरे कृष्ण, कृष्ण कृष्ण हरे हरे । हरे राम हरे राम, राम राम हरे हरे ॥",
                    "The progress indicates your temple pledge and overall India's pledge covered": "प्रगति से पता चलता है कि आपके मंदिर का संकल्प और पूरे भारत के संकल्प कितने प्रतिशत पूर्ण हुआ",
                    "The progress indicates overall India's pledge covered": "प्रगति इंगित करती है कि समग्र रूप से भारत ने प्रतिज्ञा पूरी कर ली है",
                    "Please be advised that if you wish to change your profile details, kindly contact us at": "कृपया ध्यान दें कि यदि आप अपनी प्रोफ़ाइल विवरण बदलना चाहते हैं, तो कृपया हमसे संपर्क करें",
                    "Category and Zone wise Distribution of Books": "श्रेणी और क्षेत्रानुसार पुस्तकों का वितरण",
                    "Book and Zone wise Distribution of Books(Count)": "पुस्तकों का पुस्तक एवं क्षेत्रानुसार वितरण (गणना)",
                    "Category and Zone wise Distribution of Books(Points)": "पुस्तकों का श्रेणी एवं क्षेत्रानुसार वितरण (अंक)",
                    "हिन्दी": "English",
                    // Add more translations here...
                },
            },
        },
        fallbackLng: 'en', // Fallback language if the user's language is not available
        debug: true, // Set to false in production
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
