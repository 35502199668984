import { useContext, useEffect, useState } from "react";
import "../../css/forum.css";
import { Link, useNavigate } from "react-router-dom";
import ApiService from "../../api/ApiService";
import moment from "moment";
import notification from "../../helpers/notification";
import { ToastContainer } from "react-toastify";
import AppContext from "../../context/AppContext";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function ForumList({ item, getAllForumList }) {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const { user } = useContext(AppContext);
  const [deletePopOpen, setDeletePopOpen] = useState(false);

  const convertDateFormate = (dateString, showTime = false) => {
    return moment(dateString).format("DD MMM, YYYY hh:mm a");
  };

  const convertDateFormateToHindi = (dateString) => {
    const monthNamesInHindi = [
      "जनवरी", // January
      "फरवरी", // February
      "मार्च", // March
      "अप्रैल", // April
      "मई", // May
      "जून", // June
      "जुलाई", // July
      "अगस्त", // August
      "सितंबर", // September
      "अक्टूबर", // October
      "नवंबर", // November
      "दिसंबर", // December
    ];

    const date = moment(dateString);
    const day = date.date();
    const month = monthNamesInHindi[date.month()];
    const year = date.year();
    return `${day} ${month}, ${year}`;
  };

  const NavigateToDetails = () => {
    navigate("/forum-details/" + item.id);
  };

  const deleteForumByUser = async () => {
    const payload = {
      forum_id: item.id,
    };
    const response = await ApiService.deleteForumByUserId(payload);

    if (response.status) {
      setDeletePopOpen(false);
      notification("success", response.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const deleteConfirmation = () => {
    setDeletePopOpen(true);
  };

  const handleHideModal = () => {
    setDeletePopOpen(false);
  };

  function isImage(fileUrl) {
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "svg"];
    const fileExtension = fileUrl?.split(".").pop().toLowerCase();
    return imageExtensions.includes(fileExtension);
  }

  return (
    <>
      <ToastContainer />
      <div className="right-section-wrapper">
        <div className="right-section-header">
          <div className="d-flex align-items-left user-header-div">
            <img className="rounded-circle" src="/images/common/default-profile.png" width="50" height="50" />

            <div className="user-details text-start ml-2">
              <h4 className="user-name">{i18n.language === "hi" ? item?.hindiname : item.user_name}</h4>
              <small className="header-name-span" style={{ fontWeight: "500", fontSize: "16px" }}>
                {i18n.language === "hi" ? convertDateFormateToHindi(item.createdAt) : convertDateFormate(item.createdAt)}
              </small>
            </div>
          </div>
          <div>
            {user.id == item.user_id && (
              <svg
                onClick={deleteConfirmation}
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-trash cursor-pointer"
                viewBox="0 0 16 16"
              >
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
              </svg>
            )}
          </div>
        </div>
        <Link to={"/forum-details/" + item.id} className="answer-section mt-2">
          <div className="forum-image-div">
            {isImage(item.file) ? (
              <img src={item.file} className="forum-image" />
            ) : (
              <img src="/images/common/srila-prabhupada-books.png" className="forum-image" />
            )}
          </div>
          <div className="forum-ans-section">
            <p className="user-question">{item.title}</p>
            {item.description != null && (
              <p className="user-answer read-more">
                {item.description.length > 250 ? (
                  <>
                    {item.description.slice(0, 250) + "..."}
                    <a className="read-more-link">
                      <span className="continue-reading">
                        {t("Continue Reading...")}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrow-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                          />
                        </svg>
                      </span>
                    </a>
                  </>
                ) : (
                  item.description
                )}
              </p>
            )}
          </div>
        </Link>
        <div className="d-flex share-reply-section mt-3">
          <span className="answers-stats">
            <svg width="18" height="18" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.66049 16H6.34049C4.86716 16 3.66716 14.8133 3.66716 13.3533V8.66668H2.29382C1.47382 8.66668 0.773824 8.19334 0.460491 7.43334C0.147158 6.68001 0.313824 5.84667 0.893824 5.26667L5.11382 0.786675C6.16049 -0.259992 7.84049 -0.259992 8.87383 0.773341L13.1205 5.28667C13.6872 5.84667 13.8538 6.68001 13.5405 7.43334C13.2272 8.18668 12.5272 8.66001 11.7138 8.66668H10.3405V13.3533C10.3405 14.8133 9.14049 16 7.66716 16H7.66049ZM7.00049 1.33334C6.66049 1.33334 6.32716 1.46001 6.06716 1.72001L1.84716 6.20001C1.54716 6.50001 1.65382 6.83334 1.68716 6.92668C1.72716 7.02001 1.88716 7.33334 2.28716 7.33334H4.32049C4.68716 7.33334 4.98716 7.63334 4.98716 8.00001V13.3533C4.98716 14.08 5.58716 14.6667 6.32716 14.6667H7.64716C8.38716 14.6667 8.98716 14.0733 8.98716 13.3533V8.00001C8.98716 7.63334 9.28716 7.33334 9.65382 7.33334H11.6938C12.0938 7.33334 12.2538 7.02001 12.2938 6.92668C12.3338 6.83334 12.4405 6.50001 12.1538 6.21334L7.92716 1.71334C7.67382 1.46001 7.34049 1.33334 7.00716 1.33334H7.00049Z"
                fill="#43567D"
              />
            </svg>
            &nbsp;&nbsp;
            {item.upvotes_count}&nbsp;{t("Likes")}
          </span>
          <span className="answers-stats">
            <svg width="17" height="18" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.01275 12.3807C3.12952 12.498 3.219 12.6395 3.27481 12.7953C3.33062 12.951 3.35138 13.1172 3.33562 13.2819C3.25637 14.0441 3.10647 14.7974 2.88787 15.5319C4.45725 15.1685 5.41575 14.7478 5.85113 14.5273C6.09807 14.4022 6.38245 14.3725 6.64987 14.444C7.41634 14.6491 8.20656 14.752 9 14.75C13.4955 14.75 16.875 11.5921 16.875 8C16.875 4.40788 13.4955 1.25 9 1.25C4.5045 1.25 1.125 4.409 1.125 8C1.125 9.6515 1.81912 11.1838 3.01275 12.3807ZM2.45812 16.7739C2.19156 16.8267 1.92415 16.875 1.656 16.919C1.431 16.955 1.26 16.721 1.34888 16.5117C1.4485 16.2761 1.54005 16.0371 1.62337 15.7951L1.62675 15.7839C1.90575 14.9739 2.133 14.0424 2.21625 13.175C0.835875 11.7913 0 9.98 0 8C0 3.65075 4.02975 0.125 9 0.125C13.9703 0.125 18 3.65075 18 8C18 12.3492 13.9703 15.875 9 15.875C8.10856 15.877 7.22076 15.7612 6.35962 15.5308C5.77462 15.8266 4.51575 16.3655 2.45812 16.7739Z"
                fill="#43567D"
              />
            </svg>
            &nbsp;&nbsp;
            {item.answer_count}&nbsp;{item.answer_count > 1 ? t("Answers") : t("Answer")}
          </span>
          {/* <div className="ms-auto">
            <button onClick={NavigateToDetails} a className="blog-post-comment">
              Answer
            </button>
          </div> */}
        </div>
      </div>
      <Modal centered show={deletePopOpen} onHide={handleHideModal}>
        <div className="modal-wrap">
          <p className="card-text p-3">{t("Are you sure you want to delete the question?")}</p>

          <div className="d-flex justify-content-center mb-2 modal-gap">
            <button className="btn-primarysave custom-button" onClick={deleteForumByUser}>
              {t("Yes")}
            </button>

            <button className="btn-primarysave custom-button" onClick={() => setDeletePopOpen(false)}>
              {t("Cancel")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
