import { useContext, useState } from "react";
import "../../css/forum.css";
import ApiService from "../../api/ApiService";
import DragAndDrop from "../common/dragAndDrop";
import AppContext from "../../context/AppContext";
import notification from "../../helpers/notification";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function ForumForm() {
  const { t, i18n } = useTranslation();

  const categories = [
    "All Categories",
    "Bhadra Purnima",
    "Gita Marathon",
    "CC Marathon",
    "Book Pricing",
    "Congregation Book Distribution",
    "Monthly Sankirtan Festival",
    "Vidya Daan",
    "Corporate Book Distribution",
    "Success Stories",
    "Others",
  ];

  const { user } = useContext(AppContext);
  const [formData, setFormData] = useState({ title: "", category: categories[0] });
  const [uploadedFile, setUploadedFile] = useState("");
  const [loading, setLoading] = useState(false);

  const HandleChangeUpdateForm = (key, value) => {
    const tempData = { ...formData };
    tempData[key] = value;
    setFormData(tempData);
  };

  const handleSubmitFormData = async (e) => {
    e.preventDefault();
    if (formData.title == "") {
      notification("error", "Please enter the forum title");
      return false;
    }
    if (formData.category == "" || formData.category == "Select Category") {
      notification("error", "Please select category");
      return false;
    }

    setLoading(true);

    const formDataWithFile = new FormData();
    formDataWithFile.append("title", formData.title);
    formDataWithFile.append("category", formData.category);
    formDataWithFile.append("user_id", user.id);
    formDataWithFile.append("user_name", user.name);
    formDataWithFile.append("hindiname", user.hindiname);
    formDataWithFile.append("file", uploadedFile);

    notification("success", "Question submitted successfully.");
    setTimeout(() => {
      window.location.reload();
    }, 3000);

    const response = await ApiService.userForumQuestion(formDataWithFile);

    if (response.status) {
      // setLoading(false);
      setFormData({ title: "", category: categories[0] });
      setUploadedFile("");
    } else {
      notification("error", "Something went wrong.");
      setLoading(false);
    }
  };

  return (
    <div className="forum-right">
      <ToastContainer />
      <div className="right-section-wrapper ask-question-form">
        <div className="container">
          <div className="edit-profile-wrapper">
            <h5 className="header-text">{t("Ask Question")}</h5>
            <form onSubmit={handleSubmitFormData}>
              <div className="row">
                <div className="col-md-6">
                  <div className="col-md-12 input-bottom">
                    <label className="label-text mb-1">{t("Category")}</label>
                    <select
                      className="form-select ask-question-select"
                      aria-label="select example"
                      onChange={(e) => {
                        HandleChangeUpdateForm("category", e.target.value);
                      }}
                      value={formData.category}
                    >
                      {categories.map((category, i) => (
                        <option className="filter-text" key={category} value={category} disabled={category == "Select Category"}>
                          {t(category)}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-12 input-bottom">
                    <label className="label-text mb-1">{t("Question")}</label>
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder={t("Enter question")}
                      maxLength={250}
                      onChange={(e) => {
                        HandleChangeUpdateForm("title", e.target.value);
                      }}
                      rows={5}
                    />
                  </div>
                  <div className="col-md-12 input-bottom">
                    <button type="submit" className="notification-load-more" disabled={loading}>
                      {t("Submit")}
                    </button>
                  </div>
                </div>
                <div className="col-lg-6">
                  <label className="label-text">{t("Upload File")}</label>
                  <div className="upload-photo mt-2">
                    <DragAndDrop uploadedFile={uploadedFile} setUploadedFile={setUploadedFile} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
