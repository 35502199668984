import moment from "moment";
import { useContext, useEffect, useState } from "react";
import ApiService from "../../api/ApiService";
import AppContext from "../../context/AppContext";
import { ToastContainer } from "react-toastify";
import notification from "../../helpers/notification";
import { useTranslation } from "react-i18next";

export default function AnswerCommentsItem({ item, answerId, forumId }) {
  const { user } = useContext(AppContext);

  const [userReplyText, setUserReplyText] = useState("");
  const [viewAllReplies, setViewAllReplies] = useState("");
  const [replyCommentId, setReplyCommentId] = useState("");
  const [showAllReplies, setShowAllReplies] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const convertDateFormate = (dateString, showTime = false) => {
    return moment(dateString).format("DD MMM, YYYY hh:mm a");
  };

  const showReplayTextBox = (commentId) => {
    setReplyCommentId(commentId);
  };

  const viewAllUserReplies = async (commentId = item.id) => {
    const response = await ApiService.viewAllForumUserReplies(commentId, answerId, forumId);
    if (response.status) {
      setViewAllReplies(response.data);
    }
  };

  const postForumUserReplies = async () => {
    if (userReplyText != "") {
      const payload = {
        user_id: user.id,
        user_name: user.name,
        comments: userReplyText,
        forum_id: forumId,
        comment_id: replyCommentId,
        answer_id: answerId,
        hindiname: user.hindiname,
      };

      setLoading(true);
      const response = await ApiService.postForumUserReplies(payload);
      setLoading(false);

      if (response.status) {
        notification("success", "Comment added successfully.");
        setReplyCommentId("");
        setUserReplyText("");
        viewAllUserReplies(payload.comment_id);
      }
    }
  };

  useEffect(() => {
    viewAllUserReplies();
  }, []);

  const convertDateFormateToHindi = (dateString) => {
    const monthNamesInHindi = [
      "जनवरी", // January
      "फरवरी", // February
      "मार्च", // March
      "अप्रैल", // April
      "मई", // May
      "जून", // June
      "जुलाई", // July
      "अगस्त", // August
      "सितंबर", // September
      "अक्टूबर", // October
      "नवंबर", // November
      "दिसंबर", // December
    ];

    const date = moment(dateString);
    const day = date.date();
    const month = monthNamesInHindi[date.month()];
    const year = date.year();
    return `${day} ${month}, ${year}`;
  };

  return (
    <div className="answer-comments-item-list">
      <ToastContainer />
      <div className="d-flex align-items-left user-header-div mt-3">
        <img className="rounded-circle" src="/images/common/default-profile.png" width="40" height="40" />
        <div className="user-details text-start ml-2">
          <h4 className="mb-0 user-name">{i18n.language === "hi" ? item.hindiname : item.user_name}</h4>
          <small className="header-name-span">
            {i18n.language === "hi" ? convertDateFormateToHindi(item.createdAt) : convertDateFormate(item.createdAt)}
          </small>
        </div>
      </div>
      <div className="forum-ans-section">
        <p className="user-answer">{item.comments}</p>
      </div>
      <div className="d-flex">
        <a
          className="blog-details-date"
          onClick={() => {
            showReplayTextBox(item.id);
          }}
        >
          {t("Reply")}
        </a>
        &nbsp;&nbsp;&nbsp;
        {viewAllReplies.length > 0 && (
          <a
            className="blog-details-date"
            onClick={() => {
              viewAllUserReplies(item.id);
              if (showAllReplies) {
                setShowAllReplies(false);
              } else {
                setShowAllReplies(true);
              }
            }}
          >
            {showAllReplies ? t("Hide replies") : t("View replies")}
          </a>
        )}
      </div>
      {replyCommentId == item.id && (
        <>
          <div className="form-group">
            <textarea
              className="form-control text-area mt-2 mb-2"
              id="exampleFormControlTextarea1"
              rows="2"
              onChange={(event) => setUserReplyText(event.target.value)}
            />
          </div>
          {userReplyText != "" && replyCommentId == item.id && (
            <button className="mb-2 blog-post-comment reply-button" onClick={postForumUserReplies} disabled={loading}>
              Post
            </button>
          )}
        </>
      )}
      {viewAllReplies.length > 0 &&
        showAllReplies &&
        viewAllReplies.map((item, i) => <AnswerCommentsItem key={i} item={item} answerId={answerId} forumId={forumId} index={i} />)}
    </div>
  );
}
