import { useContext, useEffect, useState } from "react";
import ApiService from "../../api/ApiService";
import NotificationItem from "./notificationItem";
import InfiniteScrollComponent from "../common/infiniteScroll";
import { useTranslation } from "react-i18next";
import AppContext from "../../context/AppContext";
import NoDataFound from "../common/NoDataFound";

export default function ProfileNotification() {
  const { t, i18n } = useTranslation();
  const [notifications, setNotifications] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const { user } = useContext(AppContext);
  const [isAPILoading, setIsAPILoading] = useState(true);

  const getUserNotification = async (isDeleted = false, page = pageNumber) => {
    try {
      if (isDeleted) {
        const pageNumbers = Array.from({ length: page }, (_, i) => i + 1);

        const promises = pageNumbers.map((list) => ApiService.getUserNotifications(list, user.id));

        const responses = await Promise.all(promises);
        setIsAPILoading(false);

        const notificationData = [];
        let totalCount = 0;

        responses.forEach((response) => {
          if (response.status) {
            if (response.data.length > 0) {
              notificationData.push(...response.data);
              totalCount = response.total_count;
            }
          }
        });

        setNotifications(notificationData);
        setNotificationsCount(totalCount);
      } else {
        const response = await ApiService.getUserNotifications(page, user.id);
        setIsAPILoading(false);

        if (response.status) {
          if (response.data.length > 0) {
            setNotificationsCount(response.total_count);
            setNotifications((prevNotifications) => [...prevNotifications, ...response.data]);
          }
        } else {
          setNotifications([]);
        }
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setNotifications([]);
      setNotificationsCount(0);
    }
  };

  useEffect(() => {
    getUserNotification(false, pageNumber);
  }, [pageNumber]);

  return (
    <div className="container">
      <span className="header-text">&nbsp;{t("Notifications")}</span>
      {!isAPILoading && (
        <div className="mt-2">
          {notifications.length > 0 ? (
            <>
              {notifications.map((v, i) => (
                <NotificationItem item={v} key={v.id} getUserNotification={getUserNotification} />
              ))}
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
      )}
      {notifications.length > 0 && notifications.length != notificationsCount && (
        <div className="input-bottom ms-auto load-more-div">
          <button
            type="submit"
            className="notification-load-more"
            onClick={() => {
              setPageNumber(pageNumber + 1);
            }}
          >
            {t("Load More")}
          </button>
        </div>
      )}
    </div>
  );
}
