import moment from "moment";
import { useContext, useEffect, useState } from "react";
import ApiService from "../../api/ApiService";
import AppContext from "../../context/AppContext";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import notification from "../../helpers/notification";

export default function BlogReplies({ item, blogId, blogCommentId, index, replyId = "null" }) {
  const { t, i18n } = useTranslation();

  const { user } = useContext(AppContext);
  const [userReplyText, setUserReplyText] = useState("");
  const [viewAllReplies, setViewAllReplies] = useState("");
  const [replyCommentId, setReplyCommentId] = useState("");
  const [showAllReplies, setShowAllReplies] = useState(false);
  const [loading, setLoading] = useState(false);

  const convertDateFormate = (dateString, showTime = false) => {
    return moment(dateString).format("DD MMM, YYYY hh:mm a");
  };

  const convertDateFormateToHindi = (dateString) => {
    const monthNamesInHindi = [
      "जनवरी", // January
      "फरवरी", // February
      "मार्च", // March
      "अप्रैल", // April
      "मई", // May
      "जून", // June
      "जुलाई", // July
      "अगस्त", // August
      "सितंबर", // September
      "अक्टूबर", // October
      "नवंबर", // November
      "दिसंबर", // December
    ];

    const date = moment(dateString);
    const day = date.date();
    const month = monthNamesInHindi[date.month()];
    const year = date.year();
    return `${day} ${month}, ${year}`;
  };

  const showReplayTextBox = (commentId) => {
    setReplyCommentId(commentId);
  };

  const viewAllUserReplies = async (commentId) => {
    const response = await ApiService.viewAllUserReplies(blogId, blogCommentId, commentId);
    if (response.status) {
      setViewAllReplies(response.data);
    }
  };

  const postBlogUserReplies = async () => {
    if (userReplyText != "") {
      const payload = {
        user_id: user.id,
        user_name: user.name,
        hindiname: user.hindiname,
        comments: userReplyText,
        blog_id: blogId,
        comment_id: blogCommentId,
        reply_id: replyId,
      };
      
      setLoading(true)
      const response = await ApiService.postBlogUserReplies(payload);
      setLoading(false)

      if (response.status) {
        notification("success", "Comment added successfully.");
        setReplyCommentId("");
        setUserReplyText("");
        viewAllUserReplies(replyId != "null" ? replyId : "null");
      } else {
        notification("error", "Something went wrong.");
      }
    }
  };

  useEffect(() => {
    viewAllUserReplies(replyId != "null" ? replyId : "null");
  }, []);

  return (
    <div className={"comments-list" + (index != 0 ? " comment-border" : "")} key={item.id}>
      <ToastContainer />
      <img src="/images/common/default-profile.png" className="comments-img" height="50" width="50" />
      <div className="popular-info">
        <p className="comment-name">{i18n.language === "hi" ? item.hindiname : item.user_name}</p>
        <div className="comment-date"> {i18n.language === "hi" ? convertDateFormateToHindi(item.createdAt) : convertDateFormate(item.createdAt)}</div>
        <p className="comment-content mt-2">{item.comments}</p>
        <div className="d-flex mt-1">
          <a
            className="comment-reply"
            onClick={() => {
              showReplayTextBox(item.id);
            }}
          >
            {t("Reply")}
          </a>
          &nbsp;&nbsp;&nbsp;
          {viewAllReplies.length > 0 && (
            <a
              className="comment-reply"
              onClick={() => {
                viewAllUserReplies(item.id);
                if (showAllReplies) {
                  setShowAllReplies(false);
                } else {
                  setShowAllReplies(true);
                }
              }}
            >
              {showAllReplies ? t("Hide replies") : t("View replies")}
            </a>
          )}
        </div>
        {replyCommentId == item.id && (
          <>
            <div className="form-group">
              <textarea
                className="form-control text-area mt-4"
                id="exampleFormControlTextarea1"
                rows="2"
                onChange={(event) => setUserReplyText(event.target.value)}
              />
            </div>

            <button className="mt-4 blog-post-comment reply-button" onClick={postBlogUserReplies} disabled={loading}>
              Post
            </button>
          </>
        )}
        {viewAllReplies.length > 0 &&
          showAllReplies &&
          viewAllReplies.map((item, i) => (
            <BlogReplies key={i} item={item} blogId={blogId} blogCommentId={blogCommentId} index={i} replyId={item.id} />
          ))}
      </div>
    </div>
  );
}
