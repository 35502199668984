import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function DragAndDrop({ uploadedFile = "", setUploadedFile = () => {} }) {
  const { t } = useTranslation();
  const [uploadedImage, setUploadedImage] = useState("");

  useEffect(() => {
    if (uploadedFile == "") {
      setUploadedImage("");
    }
  }, [uploadedFile]);

  // Drag and Drop
  const handleDrop = (e) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  };

  // Select File
  const handleFileInputChange = (e) => {
    e.preventDefault();
    handleFiles(e.target.files);
  };

  const handleFiles = (files) => {
    setUploadedFile(null);

    const file = files[0];

    if (file) {
      const fileSize = file.size / (1024 * 1024); // in MB
      if (fileSize > 2) {
        alert(t("File size exceeds 2MB. Please upload a smaller file."));
        return;
      }

      setUploadedFile(file);
      const imageURL = URL.createObjectURL(file);
      setUploadedImage(imageURL);
    }
  };

  return (
    <div className="drag-drop" onClick={() => document.querySelector("input[type=file]").click()}>
      <div onDrop={handleDrop} onDragOver={(e) => e.preventDefault()} className="upload-div border">
        <p className="file-upload-text">
          <span className="text-danger">{t("Click to Upload")}</span> {t("or drag and drop")}
        </p>

        <p className="file-upload-text">{"(" + t("Max. File size: 2 MB") + ")"}</p>
        <input type="file" onChange={handleFileInputChange} className="d-none" accept="*" />
        {uploadedFile != "" && (
          <div className="text-center mt-1">
            {uploadedFile?.type?.includes("image") && uploadedImage != "" ? (
              <img src={uploadedImage} height="150" width="250" alt={t("Uploaded File")} />
            ) : (
              <p>{uploadedFile?.name}</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
