import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation(); // Initialize translation

  return (
    <footer className="footer mt-4 py-3 bg-light text-center">
      <div className="container">
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ textAlign: "justify" }}>
          <div className="text-muted filter-text">
            {t(
              "Dedicated to His Divine Grace A.C. Bhaktivedanta Swami Prabhupada - Founder Acharya of the International Society for Krishna Consciousness."
            )}
          </div>
          <div className="text-danger filter-text">
            © 2024 ISKCON <span className="d-inline-block mx-2">|</span> {t("All Rights Reserved.")}
          </div>
        </div>
      </div>
    </footer>
  );
}
