import React, { useContext, useEffect, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import ApiService from "../../api/ApiService";
import html2canvas from "html2canvas";
import "../../css/commonCss.css";
import { useTranslation } from "react-i18next";
import AppContext from "../../context/AppContext";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function ChartEvents({ selectedEvent, type }) {
  const { t, i18n } = useTranslation();
  const { user } = useContext(AppContext);

  const zoneColors = {
    "East Zone": "#AF4B91",
    "North Zone": "#41B0AA",
    "South Zone": "#E6A632",
    "West Zone": "#8070C9",
  };

  if (user.zone_array != null && user.zone_array.length > 0) {
    let zonesList = user.zone_array.map((item) => item.split(",")[1]);

    Object.keys(zoneColors).forEach((key) => {
      if (!zonesList.includes(key)) {
        delete zoneColors[key];
      }
    });
  }

  const zoneOrder = ["East Zone", "West Zone", "South Zone", "North Zone"];

  const [zoneData, setZoneData] = useState([]);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const getAllDataByZones = async () => {
    setZoneData([]);
    try {
      let zonesList = "all";

      if (user.zone_array != null && user.zone_array.length > 0) {
        zonesList = user.zone_array.map((item) => item.split(",")[1]);
      }

      const response = await ApiService.getTempleAndBooksDataByZones(type, selectedEvent, zonesList);

      if (response.data.length > 0) {
        const zoneMap = response.data.reduce((map, zone) => {
          map[zone.name] = {
            label: zone.name,
            y: zone.dataPoints[0]?.y ?? 0,
            color: zoneColors[zone.name],
          };
          return map;
        }, {});

        const sortedData = zoneOrder.map((zoneName) => zoneMap[zoneName]).filter(Boolean);
        setZoneData(sortedData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllDataByZones();
  }, [selectedEvent]);

  const options = {
    // exportEnabled: true,
    title: {
      //   text: "Srimad Bhagavatam Sets by Zone",
    },
    axisX: {
      interval: 1,
    },
    axisY: {
      title: selectedEvent == "Bhadra Purnima" ? "Srimad Bhagavatam Set" : "Chaitanya Charitamrita Set",
      includeZero: true,
      lineThickness: 0,
      gridThickness: 1,
      gridColor: "#D3D3D3",
      gridDashType: "dot",
      tickThickness: 0,
      tickColor: "#D3D3D3",
      tickLength: 0,
      lineColor: "#D3D3D3",
      labelFormatter: (e) => Math.ceil(e.value).toLocaleString("en-IN"),
    },
    toolTip: {
      shared: true,
      contentFormatter: (e) => {
        return e.entries
          .map((entry) => {
            const value = Math.ceil(entry.dataPoint.y).toLocaleString("en-IN");
            return `<p style="color:${zoneColors[entry.dataPoint.label]}; margin:0px">${entry.dataPoint.label}: ${value}</p>`;
          })
          .join("<br/>");
      },
    },
    data: [
      {
        type: "column",
        dataPoints: zoneData,
      },
    ],
  };

  const handleDownload = () => {
    setIsButtonVisible(false);

    setTimeout(() => {
      html2canvas(document.querySelector(".book-wise-stats")).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "book-wise-stats.png";
        link.click();
      });
    }, 1000);

    setTimeout(() => {
      setIsButtonVisible(true);
    }, 1000);
  };

  return (
    <div className={"top-temples-card book-wise-stats"}>
      <div className="head-sec" style={{ border: "none" }}>
        <div className="temple-list-head-sec">
          <h2 className="stacked-head-name m-0">
            {type === "temples" ? t("Category and Zone wise Distribution of Books(Points)") : t("Book and Zone wise Distribution of Books(Count)")}
          </h2>
        </div>
        {isButtonVisible && (
          <span className="button-text-download" onClick={handleDownload}>
            {t("Download")}
          </span>
        )}
      </div>

      <div className="stacked-card">
        <CanvasJSChart options={{ ...options, dataPointWidth: 20 }} />
      </div>
    </div>
  );
}
