import React, { useEffect, useState } from "react";
import ApiService from "../api/ApiService";

const AppContext = React.createContext({});

export const AppContextProvider = ({ children }) => {
  const [loginPopup, setLoginPopup] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [showHeaderFooter, setShowHeaderFooter] = useState(true);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [historyEvent, setHistoryEvent] = useState(null);
  const [liveScoreData, setLiveScoreData] = useState([]);
  const [isLiveLoaded, setLiveIsLoaded] = useState(false);

  const getUserNotificationSCount = async (user) => {
    try {
      const res = await ApiService.getUserUnReadNotificationCount(user.id);

      if (res.status) {
        setNotificationsCount(res.new_notifications_count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getScoreLiveUpdates = async () => {
    setLiveIsLoaded(false);
    try {
      const response = await ApiService.fetchScoreLiveUpdates();
      if (response) {
        setLiveIsLoaded(true);
        if (response.data) {
          setLiveScoreData(response.data);
        }
      }
    } catch (error) {
      setLiveIsLoaded(true);
      console.log(error);
    }
  };

  useEffect(() => {
    if (user) {
      getScoreLiveUpdates();
      getUserNotificationSCount(user);
    }
  }, [user]);

  let values = {
    loginPopup,
    setLoginPopup,
    token,
    setToken,
    user,
    setUser,
    setShowHeaderFooter,
    showHeaderFooter,
    notificationsCount,
    setNotificationsCount,
    historyEvent,
    setHistoryEvent,
    isLiveLoaded,
    liveScoreData,
    setLiveIsLoaded,
    setLiveScoreData,
  };

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export default AppContext;
