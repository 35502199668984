import { useState, useContext } from "react";
import FileUploader from "../common/dragAndDrop";
import AppContext from "../../context/AppContext";
import { useTranslation } from "react-i18next";

export default function EditProfile() {
  const { user } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const [uploadedFile, setUploadedFile] = useState(null);

  const roleMap = {
    Sevak: "Book Distributor",
    "Sevak Head": "Sankirtan Leader",
    "Regional Secretary": "Regional Secretary/ Zonal Secretary",
    "Regional Zonal Secretary": "Regional Secretary Assistant",
  };

  let userRole = roleMap[user.role] || user.role;

  return (
    <div className="container">
      <div className="edit-profile-wrapper">
        <h5 className="header-text">{t("My Profile")}</h5>
        <form>
          <div className="row">
            <div className="col-md-6 input-bottom">
              <label className="label-text mb-1">{t("Name")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Enter your name")}
                value={i18n.language === "hi" ? user.hindiname : user.name}
                disabled
              />
            </div>
            <div className="col-md-6 input-bottom">
              <label className="label-text mb-1">{t("Phone")}</label>
              <input type="text" className="form-control" placeholder={t("Enter your phone number")} value={user.phone} disabled />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 input-bottom">
              <label className="label-text mb-1">{t("Email")}</label>
              <input type="text" className="form-control" placeholder={t("Enter your email")} value={user.email} disabled />
            </div>
            <div className="col-md-6 input-bottom">
              <label className="label-text mb-1">{t("Role")}</label>
              <input type="text" className="form-control" placeholder={t("Enter your role")} value={t(userRole)} disabled />
            </div>
          </div>
          <div className="row">
            {user.role != "GBC (Governing Body Commission)" && user.role != "Regional Zonal Secretary" && user.role != "Regional Secretary Assistant" && (
              <>
                <div className="col-md-6 input-bottom">
                  <label className="label-text mb-1">{t("Temple")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Enter your temple")}
                    // value={user.temple_name}
                    value={i18n.language === "hi" ? user.hinditempleName : user.temple_name}
                    disabled
                  />
                </div>
                <div className="col-md-6 input-bottom">
                  <label className="label-text mb-1">{t("Zone")}</label>
                  <input type="text" className="form-control" placeholder={t("Enter your zone")} value={t(user.zone_name)} disabled />
                </div>
              </>
            )}
          </div>
          <div className="row">
            {user.role != "GBC (Governing Body Commission)" && user.role != "Regional Zonal Secretary" && user.role != "Regional Secretary Assistant" && (
              <>
                <div className="col-md-6 input-bottom">
                  <label className="label-text mb-1">{t("State")}</label>
                  <input type="text" className="form-control" placeholder={t("Enter your state")} value={t(user.state_name)} disabled />
                </div>
                <div className="col-md-6 input-bottom">
                  <label className="label-text mb-1">{t("Note")}*</label>
                  <p style={{ color: "#6e717f" }}>
                    {t("Please be advised that if you wish to change your profile details, kindly contact us at")}{" "}
                    <a href="mailto:support@iccbrihadmridanga.com">support@iccbrihadmridanga.com</a>.
                  </p>
                </div>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
