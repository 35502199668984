import { useTranslation } from "react-i18next";

export default function NoDataFound() {
  const { t, i18n } = useTranslation();
  return (
    <div className="right-section-wrapper no-data-card">
      <h5 className="no-data-text">{t("No Data Found.")}</h5>
    </div>
  );
}
