import React, { useState, useEffect } from "react";
import ApiService from "../api/ApiService";
import "../css/quotes.css";
import { useTranslation } from "react-i18next";

export default function Quotes() {
  const { t, i18n } = useTranslation();
  const [quotesData, setQuotesData] = useState([]);

  const fetchQuotes = async () => {
    try {
      const res = await ApiService.fetchQuotes();
      setQuotesData(res); // Assuming `res` is an array of quotes
    } catch (error) {
      console.log(error);
    }
  };

  // Helper function to format date as DD-MM-YYYY
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    fetchQuotes();
  }, []);

  return (
    <div className="container dashboard-container quote-container">
      <h4 className="blog-heading mt-3 mb-3">{t("Quotes")}</h4>
      {quotesData.map((item, i) => (
        <div className={"row user-welcome-card mb-4 content-section " + ((i + 1) % 2 === 0 ? "content-reverse " : "")} key={i}>
          <div className="col-lg-3 quote-image-div">
            <div className="quote-image">
              <img src={process.env.REACT_APP_BACKEND_URL + item.image} alt="quote image" />
            </div>
          </div>

          <div className="col-lg-9 quote-content">
            <div>
              {i18n.language === "hi" ? `“${item.hindiQuote}”` : `“${item.englishQuote}”`}
              <div
                className="pledge-status-text"
                dangerouslySetInnerHTML={{
                  __html: i18n.language === "hi" ? item?.hindi_date : item?.date,
                }}
              />
              {/* <p className="pledge-status-text">– {formatDate(item.date)}</p> */}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
