import { useContext, useEffect, useState } from "react";
import { Dropdown, DropdownButton, OverlayTrigger } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ApiService from "../api/ApiService";
import AppContext from "../context/AppContext";
import TopTemples from "../components/common/topTemples";
import BooksPledgeTable from "../components/common/booksPledgeTable";

export default function TemplesStats() {
  const { user } = useContext(AppContext);
  const [templeList, setTempleList] = useState([]);
  const [templeListFromZone, setTempleListFromZone] = useState([]);
  const [selectedTempleName, setSelectedTempleName] = useState("Select Temple");
  const [selectedHindiName, setSelectedHindiName] = useState("मंदिर चुनें");
  const [selectedTempleId, setSelectedTempleId] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("Regular Update");
  const [topTemplesList, setTopTemplesList] = useState([]);
  const [userScoreData, setUserScoreData] = useState({});
  const [templeCategory, setTempleCategory] = useState("");

  const { t, i18n } = useTranslation();

  const selectTabs = [
    {
      label: t("Overall Book Distribution"),
      value: "Regular Update",
      description: t("Overall Book Distribution refers to year-round distribution and includes all marathons"),
    },
    {
      label: t("Bhadra Purnima Marathon"),
      value: "Bhadra Purnima",
      description: t("Bhadra Purnima Marathon refers to SB set distribution from Gaura Purnima till Bhadra Purnima"),
    },
    {
      label: t("Chaitanya Charitamrita Marathon"),
      value: "Chaitanya Charitamrita",
      description: t("Chaitanya Charitamrita Marathon refers to CC set distribution from 15th Jan till Gaura Purnima"),
    },
    {
      label: t("Gita Marathon"),
      value: "Gita Marathon",
      description: t("December Bhagavad Gita Marathon refers to book distribution between 15th Nov to 15th Jan"),
    },
  ];

  const fetchTempleTRankingByTempleId = async () => {
    setUserScoreData({});

    try {
      const templeData = templeListFromZone.filter((item) => item.temple_id == selectedTempleId)[0];

      const ZoneMapping = {
        EZ: "East Zone",
        NZ: "North Zone",
        SZ: "South Zone",
        WZ: "West Zone",
      };

      const categoryMapping = {
        BT: "Big Temple",
        ST: "Small Temple",
        MT: "Medium Temple",
        OP: "Outpost",
        PC: "Preaching Centre",
        EC: "Extension Centre",
      };

      setTempleCategory(categoryMapping[templeData.templePTC]);
      getTopTenTemplesList(categoryMapping[templeData.templePTC]);

      const res = await ApiService.fetchDataFromAllLiveRankingByUserId(
        selectedTempleId,
        ZoneMapping[templeData.templeDivision],
        categoryMapping[templeData.templePTC],
        templeData.templeState,
        selectedEvent
      );

      setUserScoreData(res);
    } catch (error) {
      console.log(error);
    }
  };

  const getTopTenTemplesList = async (category = "") => {
    setTopTemplesList([]);
    let res = [];

    try {
      if (category == "") {
        if (user.role == "GBC (Governing Body Commission)") {
          if (user.zone_array != null && user.zone_array.length > 0) {
            const zonesList = user.zone_array.map((item) => item.split(",")[1]);
            res = await ApiService.getGBCTopTenTemplesByZones(selectedEvent, zonesList);
          } else {
            //For GBC Top 10 Temples in all over india
            res = await ApiService.getTopTemplesList(selectedEvent, "all");
          }
        } else {
          const postData = {
            temple_array: user.temple_array,
            state_array: user.state_array,
            zone_array: user.zone_array,
            event: selectedEvent,
          };

          res = await ApiService.getRSATopTemplesList(postData);
        }
      } else {
        res = await ApiService.getTopTemplesList(selectedEvent, category);
      }

      if (res.data.length > 0) {
        setTopTemplesList(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllTempleList = async (user) => {
    try {
      let res = [];

      if (user.role == "GBC (Governing Body Commission)") {
        if (user.zone_array != null && user.zone_array.length > 0) {
          const postData = {
            state_array: [],
            zone_array: user.zone_array,
          };
          res = await ApiService.getRSATempleList(postData);
        } else {
          res = await ApiService.getAllTemplesList();
        }
      } else {
        const postData = {
          state_array: user.state_array,
          zone_array: user.zone_array,
        };

        res = await ApiService.getRSATempleList(postData);
      }

      if (res) {
        if (user.role != "GBC (Governing Body Commission)" && user.temple_array != null && user.temple_array.length > 0) {
          setTempleList(user.temple_array);
          setTempleListFromZone(res);
        } else {
          setTempleList(res);
          setTempleListFromZone(res);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //For GBC and RSA performing temples
  useEffect(() => {
    if (selectedTempleId == "" && user.role != "Sevak Head" && user?.role != "Temple President" && user?.role != "Chairman of TMC" && user?.role != "General Manager") {
      getTopTenTemplesList("");
    }
  }, [selectedEvent]);

  //For Parent performing temples
  useEffect(() => {
    if (selectedTempleId == "" && templeList.length > 0 && user.role == "Sevak Head" || user?.role == "Temple President" || user?.role == "Chairman of TMC" || user?.role =="General Manager") {
      getParentPerformingTemples(templeList);
    }
  }, [templeList, selectedEvent]);

  useEffect(() => {
    if (selectedTempleId != "") {
      fetchTempleTRankingByTempleId(selectedTempleId);
    }
  }, [selectedTempleId, selectedEvent]);

  useEffect(() => {
    if (user.role == "Sevak Head" || user?.role == "Temple President" || user?.role == "Chairman of TMC" || user?.role == "General Manager") {
      getParentTemplesData(user.temple_id);
    } else {
      getAllTempleList(user);
    }
  }, [user]);

  const handleSelect = (eventKey) => {
    const [templeId, templeName, hindiTempleName] = eventKey.split("|");
    setSelectedTempleName(templeName);
    setSelectedHindiName(hindiTempleName);
    setSelectedTempleId(templeId);
  };

  const tooltip = (description) => {
    return <div className="tooltip-custom">{t(description)}</div>;
  };

  const getParentTemplesData = async (templeId) => {
    try {
      const response = await ApiService.getParentTemplesList(templeId);

      if (response.status && response.data.length > 0) {
        const parentTempleList = response.data.filter((item) => item.temple_id != user.temple_id);

        setTempleList(parentTempleList);
        setTempleListFromZone(parentTempleList);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getParentPerformingTemples = async (templeLists) => {
    setTopTemplesList([]);
    const templeArray = templeLists.map((item) => item.temple_id);

    try {
      const templePayload = {
        templeIds: templeArray,
        event: selectedEvent,
      };

      const res = await ApiService.getParentTemplePerformingList(templePayload);

      if (res.data.length > 0) {
        setTopTemplesList(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="container common-container pledge-responsive update-responsive">
      <div className="update-score-container">
        <div className="row">
          <div className={selectedTempleName != "Select Temple" ? "col-md-5" : "col-md-12"}>
            <div className="card card-shadow">
              <div className="card-body">
                <h5 className="card-title">{t("Temple Performance")}</h5>
                <p className="card-text">{t("Select any temple to track the ranking and book wise progress of the temple.")}</p>
              </div>
            </div>
          </div>
          {selectedTempleName != "Select Temple" && (
            <div className="col-md-7">
              <div className="card card-shadow">
                <div className="card-body">
                  <h5 className="stats-header">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.4744 17.0183H5.51784C2.96254 17.0183 1.0022 16.0954 1.55903 12.3807L2.20739 7.34633C2.55064 5.49279 3.73294 4.78341 4.77032 4.78341H13.2524C14.305 4.78341 15.4187 5.54618 15.8153 7.34633L16.4637 12.3807C16.9366 15.6759 15.0297 17.0183 12.4744 17.0183Z"
                        stroke="#130F26"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.5889 4.60034C12.5889 2.61194 10.977 1.00003 8.98861 1.00003V1.00003C8.03111 0.995975 7.11143 1.3735 6.43294 2.04913C5.75445 2.72476 5.37304 3.64283 5.37305 4.60034H5.37305"
                        stroke="#130F26"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path d="M11.46 8.3532H11.4219" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M6.60114 8.3532H6.563" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    &nbsp;
                    {i18n.language === "hi"
                      ? `${t(selectedEvent)} में ${selectedHindiName || ""} के आँकड़े`
                      : `${t("Stats of")} ${selectedTempleName || ""} ${
                          selectedEvent !== "Select Event" && selectedEvent !== "Regular Update" ? t("in") + " " + t(selectedEvent) : ""
                        }`}
                  </h5>

                  <div className="card-text stats">
                    <div className="text-center">
                      <p className="ranking-status-text">{t("All India Rank")}</p>
                      <span className="ranking-count">{userScoreData.all_rank ?? "-"}</span>
                    </div>
                    <div className="text-center">
                      <p className="ranking-status-text">{t("State Rank")}</p>
                      <span className="ranking-count">{userScoreData.state_rank ?? "-"}</span>
                    </div>
                    <div className="text-center">
                      <p className="ranking-status-text">{t("Zonal Rank")}</p>
                      <span className="ranking-count">{userScoreData.zone_rank ?? "-"}</span>
                    </div>
                    <div className="text-center">
                      <p className="ranking-status-text">{t("Category Rank")}</p>
                      <span className="ranking-count">{userScoreData.temple_rank ?? "-"}</span>
                    </div>
                    <div className="text-center">
                      <p className="ranking-status-text">{t("Total Points")}</p>
                      <span className="ranking-count">
                        {userScoreData?.total_points ? Math.ceil(userScoreData?.total_points)?.toLocaleString("en-IN") : "-"}
                      </span>
                    </div>
                    <div className="text-center">
                      <p className="ranking-status-text">{t("Books Distributed")}</p>
                      <span className="ranking-count">
                        {userScoreData?.count_book ? Math.ceil(userScoreData?.count_book)?.toLocaleString("en-IN") : "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row mt-4 dashboard-container">
            <div className="col-md-12">
              <h4 className="welcome-text">{t("Temple Wise Stats")}</h4>
              <div className="ranking-filters mt-4 row user-welcome-card select-temple-card">
                <div className="events-drop-down col-lg-2 select-temple-drop-down">
                  {user.role != "GBC (Governing Body Commission)" && user?.temple_array?.length > 0 ? (
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={i18n.language === "hi" ? selectedHindiName : t(selectedTempleName)}
                      onSelect={handleSelect}
                      className="mr-3 white-dropdown"
                    >
                      <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                        {templeList.length > 0 &&
                          templeList.map((temple, index) => (
                            <Dropdown.Item
                              value={temple?.split(",")[1]}
                              eventKey={`${temple?.split(",")[1]}|${temple?.split(",")[2]}|${temple?.split(",")[3]}`}
                              key={index}
                            >
                              {temple?.split(",")[i18n.language === "hi" ? 3 : 2]}
                            </Dropdown.Item>
                          ))}
                      </div>
                    </DropdownButton>
                  ) : (
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={i18n.language === "hi" ? selectedHindiName : t(selectedTempleName)}
                      onSelect={handleSelect}
                      className="mr-3 white-dropdown"
                    >
                      <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                        {templeList.length > 0 &&
                          templeList.map((temple, index) => (
                            <Dropdown.Item
                              value={temple.temple_id}
                              eventKey={`${temple.temple_id}|${temple.templeName}|${temple.hinditempleName}`}
                              key={index}
                            >
                              {i18n.language === "hi" ? temple.hinditempleName : temple.templeName}
                            </Dropdown.Item>
                          ))}
                      </div>
                    </DropdownButton>
                  )}
                </div>
                <div className="tabs-wrapper col-lg-10" style={{ height: "73px", boxShadow: "none" }}>
                  {selectTabs.map((item, i) => (
                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={tooltip(item.description)}>
                      <div className="event-div" key={i}>
                        <div
                          className={"select-tab-div " + (selectedEvent == item.value ? " active-tab" : "")}
                          onClick={() => setSelectedEvent(item.value)}
                        >
                          <span className={selectedEvent == item.value ? "tab-event-text-active" : "tab-event-text"}>{item.label}</span>
                        </div>
                      </div>
                    </OverlayTrigger>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-6">
              {selectedTempleId != "" ? (
                <BooksPledgeTable selectedEvent={selectedEvent} isRSAUser={false} templeId={selectedTempleId} />
              ) : (
                <BooksPledgeTable
                  selectedEvent={selectedEvent}
                  isRSAUser={true}
                  isGBC={user.role == "GBC (Governing Body Commission)" ? true : false}
                  parentTempleList={user.role == "Sevak Head" || user?.role == "Temple President" || user?.role == "Chairman of TMC" || user?.role == "General Manager" ? templeList?.map((item) => item.templeName + "," + item.temple_id) : false}
                />
              )}
            </div>
            <div className="col-md-6 graph-stats-div">
              <TopTemples
                topTemplesList={topTemplesList}
                user={user}
                isGBCUser={true}
                eventWiseCard={true}
                isRSATemples={user.role == "GBC (Governing Body Commission)" ? false : true}
                templeCategory={templeCategory}
                showFullCard={true}
                selectedEvent={selectedEvent}
                gbcZonesList={user.zone_array != null && user.zone_array.length > 0 ? true : false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
