import { useContext, useEffect, useState } from "react";
import "../css/blogDetails.css";
import { Link, useParams } from "react-router-dom";
import ApiService from "../api/ApiService";
import moment from "moment";
import AppContext from "../context/AppContext";
import BlogReplies from "../components/common/blogReply";
import { useTranslation } from "react-i18next";
import notification from "../helpers/notification";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function BlogDetails() {
  const { t, i18n } = useTranslation();
  console.log("language", i18n.language);

  const [blogDetails, setBlogsDetails] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [userComment, setUserComment] = useState("");
  const [blogCommentsList, setBlogCommentsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const { user } = useContext(AppContext);

  const getBlogsDetails = async (blogId) => {
    const response = await ApiService.getBlogDetailsById(blogId);
    if (response.status) {
      setBlogsDetails(response.data);
    }
  };

  const getBlogsList = async (category) => {
    const categoryCounts = {};
    const response = await ApiService.getBlogsListData("", category, 0);
    if (response.status) {
      response.data.forEach((item) => {
        const category = item.category;
        if (categoryCounts[category]) {
          categoryCounts[category]++;
        } else {
          categoryCounts[category] = 1;
        }
      });

      const result = Object.keys(categoryCounts).map((category) => ({
        category: category,
        count: categoryCounts[category],
      }));

      setCategoriesList(result);
      const popularList = response.data.sort((a, b) => b.popular_count - a.popular_count);
      setCategoryList(popularList.slice(0, 4));
    }
  };

  useEffect(() => {
    const blogId = id;
    getBlogsDetails(blogId);
    getBlogUserComments(blogId);
    getBlogsList("All Categories");
  }, [id]);
  const convertDateFormateToHindi = (dateString) => {
    const monthNamesInHindi = [
      "जनवरी", // January
      "फरवरी", // February
      "मार्च", // March
      "अप्रैल", // April
      "मई", // May
      "जून", // June
      "जुलाई", // July
      "अगस्त", // August
      "सितंबर", // September
      "अक्टूबर", // October
      "नवंबर", // November
      "दिसंबर", // December
    ];

    const date = moment(dateString);
    const day = date.date();
    const month = monthNamesInHindi[date.month()];
    const year = date.year();
    return `${day} ${month}, ${year}`;
  };

  const convertDateFormate = (dateString) => {
    return moment(dateString).format("DD MMM, YYYY");
  };

  const postBlogUserComments = async () => {
    if (userComment !== "") {
      const payload = {
        user_id: user.id,
        user_name: user.name,
        hindiname: user.hindiname,
        comments: userComment,
        blog_id: id,
      };

      setLoading(true);
      const response = await ApiService.postBlogComments(payload);
      setLoading(false);

      if (response.status) {
        notification("success", "Comment added successfully.");
        getBlogUserComments(id);
        setUserComment("");
      } else {
        notification("error", "Something went wrong.");
      }
    }
  };

  const handleTextareaChange = (event) => {
    setUserComment(event.target.value);
  };

  const getBlogUserComments = async (blogId) => {
    const response = await ApiService.getBlogComments(blogId);
    if (response.status) {
      setBlogCommentsList(response.data);
    }
  };

  return (
    <div className="container blog-section">
      <ToastContainer />
      <div className="">
        <div className="blog-container">
          <div className="row">
            <div className="col-md-8">
              <div className="blog-details-container">
                <div style={{ padding: "0px 14px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="#43567d"
                    className="bi bi-arrow-left-short cursor-pointer blog-back"
                    viewBox="0 0 16 16"
                    onClick={() => window.history.back()}
                    style={{ border: "1px solid #dbdff2", backgroundColor: "#f8f9ff", borderRadius: "6px" }}
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
                    />
                  </svg>
                  &nbsp; &nbsp;
                  <span className="blog-category">{t(blogDetails.category)}</span>
                  <h4 className="main-title mt-4">{i18n.language === "hi" ? blogDetails.hindititle : blogDetails.title}</h4>
                  <div className="blog-details-date">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.5 15C3.36437 15 0 11.6356 0 7.5C0 3.36437 3.36437 0 7.5 0C11.6356 0 15 3.36437 15 7.5C15 11.6356 11.6356 15 7.5 15ZM7.5 1.25C4.05375 1.25 1.25 4.05375 1.25 7.5C1.25 10.9462 4.05375 13.75 7.5 13.75C10.9462 13.75 13.75 10.9462 13.75 7.5C13.75 4.05375 10.9462 1.25 7.5 1.25ZM9.0625 10.2063C9.36187 10.0337 9.46438 9.65125 9.29125 9.3525L8.125 7.3325V3.75C8.125 3.405 7.84562 3.125 7.5 3.125C7.15438 3.125 6.875 3.405 6.875 3.75V7.5C6.875 7.61 6.90375 7.7175 6.95875 7.8125L8.20875 9.9775C8.325 10.1781 8.53438 10.29 8.75063 10.29C8.85688 10.29 8.96437 10.2631 9.0625 10.2063Z"
                        fill="#909AAD"
                      />
                    </svg>
                    &nbsp;&nbsp;
                    {i18n.language === "hi" ? convertDateFormateToHindi(blogDetails.createdAt) : convertDateFormate(blogDetails.createdAt)}
                    &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_897_285)">
                        <path
                          d="M7.31207 5.6875C7.31207 6.136 6.94807 6.5 6.49957 6.5C6.05107 6.5 5.68707 6.136 5.68707 5.6875C5.68707 5.239 6.05107 4.875 6.49957 4.875C6.94807 4.875 7.31207 5.239 7.31207 5.6875ZM9.2079 4.875C8.7594 4.875 8.3954 5.239 8.3954 5.6875C8.3954 6.136 8.7594 6.5 9.2079 6.5C9.6564 6.5 10.0204 6.136 10.0204 5.6875C10.0204 5.239 9.6564 4.875 9.2079 4.875ZM3.79123 4.875C3.34273 4.875 2.97873 5.239 2.97873 5.6875C2.97873 6.136 3.34273 6.5 3.79123 6.5C4.23973 6.5 4.60373 6.136 4.60373 5.6875C4.60373 5.239 4.23973 4.875 3.79123 4.875ZM12.9996 2.16667V8.66667C12.9996 9.86158 12.0278 10.8333 10.8329 10.8333H9.28807L7.20373 12.5558C7.00819 12.7303 6.7574 12.818 6.5039 12.818C6.24607 12.818 5.98661 12.727 5.77969 12.5434L3.74898 10.8333H2.16569C0.970774 10.8333 -0.000976562 9.86158 -0.000976562 8.66667V2.16667C-0.000434896 0.97175 0.971315 0 2.16623 0H10.8329C12.0278 0 12.9996 0.97175 12.9996 2.16667ZM11.9162 2.16667C11.9162 1.56921 11.4304 1.08333 10.8329 1.08333H2.16623C1.56877 1.08333 1.0829 1.56921 1.0829 2.16667V8.66667C1.0829 9.26413 1.56877 9.75 2.16623 9.75H3.94723C4.07452 9.75 4.19857 9.79496 4.29661 9.87729L6.48927 11.7244L8.74857 9.87404C8.84552 9.79388 8.9674 9.75 9.09361 9.75H10.8334C11.4309 9.75 11.9168 9.26413 11.9168 8.66667L11.9162 2.16667Z"
                          fill="#909AAD"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_897_285">
                          <rect width="13" height="13" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    &nbsp;&nbsp;
                    {blogCommentsList.length} {blogCommentsList.length > 1 ? t("Comments") : t("Comment")}
                  </div>
                </div>
                <div className="blog-details-image">
                  <img src={process.env.REACT_APP_BACKEND_URL + blogDetails.image} alt="blog-image" className="blog-img" />
                </div>
                <div className="description mt-4">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: i18n.language === "hi" ? blogDetails.hindidescription : blogDetails.description,
                    }}
                  />
                </div>
              </div>
              <div className="blog-details-container mt-4">
                <div className="mt-2 user-comments">
                  <h5 className="blog-category-list">{t("Write a comment")}</h5>
                  <div className="form-group">
                    <textarea
                      className="form-control custom-textarea text-area mt-4"
                      id="exampleFormControlTextarea1"
                      rows="4"
                      onChange={handleTextareaChange}
                      placeholder={t("write your comment here")}
                      value={userComment}
                    />
                  </div>
                  <button className="mt-4 blog-post-comment" onClick={postBlogUserComments} disabled={userComment == "" || loading}>
                    {t("Post Comments")}
                  </button>
                </div>
              </div>
              {blogCommentsList.length > 0 && (
                <div className="mt-4 user-comments blog-details-container">
                  <h5 className="blog-category-list mt-2">
                    {blogCommentsList.length} {blogCommentsList.length > 1 ? t("Comments") : t("Comment")}
                  </h5>

                  {blogCommentsList.map((item, i) => (
                    <BlogReplies key={i} item={item} blogId={id} index={i} blogCommentId={item.id} />
                  ))}
                </div>
              )}
            </div>
            <div className="col-md-3">
              <div className="blog-right">
                <h5 className="blog-category-list">{t("Categories")}</h5>
                <div className="mt-4">
                  {categoriesList.map((v, i) => (
                    <Link to={"/blogs/" + v.category} className="item-list" key={i}>
                      <p className="item-text">{t(v.category)}</p>
                      <span className="blog-category-count">{v.count}</span>
                    </Link>
                  ))}
                </div>
              </div>
              <div className="mt-4 popular-wraper blog-right">
                <h5 className="blog-category-list">{t("Popular")}</h5>
                <div className="">
                  {categoryList.map((v, i) => (
                    <Link to={"/blog-details/" + v.id} className="item-list mt-2" key={i}>
                      <img src={process.env.REACT_APP_BACKEND_URL + v.image} className="popular-img" />
                      <div className="popular-info">
                        <span className="popular-title">{i18n.language === "hi" ? v.hindititle : v.title}</span>
                        <div className="blog-details-date" style={{ display: "flex", alignItems: "center" }}>
                          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M7.5 15C3.36437 15 0 11.6356 0 7.5C0 3.36437 3.36437 0 7.5 0C11.6356 0 15 3.36437 15 7.5C15 11.6356 11.6356 15 7.5 15ZM7.5 1.25C4.05375 1.25 1.25 4.05375 1.25 7.5C1.25 10.9462 4.05375 13.75 7.5 13.75C10.9462 13.75 13.75 10.9462 13.75 7.5C13.75 4.05375 10.9462 1.25 7.5 1.25ZM9.0625 10.2063C9.36187 10.0337 9.46438 9.65125 9.29125 9.3525L8.125 7.3325V3.75C8.125 3.405 7.84562 3.125 7.5 3.125C7.15438 3.125 6.875 3.405 6.875 3.75V7.5C6.875 7.61 6.90375 7.7175 6.95875 7.8125L8.20875 9.9775C8.325 10.1781 8.53438 10.29 8.75063 10.29C8.85688 10.29 8.96437 10.2631 9.0625 10.2063Z"
                              fill="#909AAD"
                            />
                          </svg>
                          &nbsp;{i18n.language === "hi" ? convertDateFormateToHindi(v.createdAt) : convertDateFormate(v.createdAt)}
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
