import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiService from "../api/ApiService";
import "../css/app.css";

const Unsubscribe = () => {
    const { id } = useParams();
    const [status, setStatus] = useState("success"); // 'loading', 'success', 'error'

    useEffect(() => {
        const handleUnsubscribe = async () => {
            try {
                const response = await ApiService.unsubscribeUser(id);


                if (response) {
                    setStatus("success");
                }
            } catch (error) {
                console.error("Error unsubscribing:", error);
                setStatus("error");
            }
        };

        handleUnsubscribe();
    }, [id]);

    if (status === "success") {
        return (
            <div className="unsubscribe-div">
                <center>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        fill="#28a745"
                        className="bi bi-check-circle"
                        viewBox="0 0 16 16"
                    >
                        <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zM3.97 8.03a.75.75 0 1 1 1.06-1.06L7 8.94l3.97-3.97a.75.75 0 1 1 1.06 1.06L7 10.06 3.97 8.03z" />
                    </svg>
                    <h4 className="unsubscribe-welcome-text">Unsubscribed Successfully!</h4>
                </center>
                <div className="text-center mt-3">
                    <span className="unsubscribe-sub-text">
                        You have successfully unsubscribed from our emails. If you wish to resubscribe, please visit our website.
                    </span>
                </div>
            </div>
        );
    }

    // Default loading state
    return (
        <div className="unsubscribe-div">
            <center>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="#9f1d21"
                    className="bi bi-arrow-repeat"
                    viewBox="0 0 16 16"
                >
                    <path d="M2 8a6 6 0 1 1 11.79 1h1.708A7.5 7.5 0 1 0 0 8a.5.5 0 0 1 1 0z" />
                    <path d="M8.354 4.646a.5.5 0 0 0-.708 0l-2 2a.5.5 0 0 0 0 .708l2 2a.5.5 0 0 0 .708-.708L6.707 7H10a.5.5 0 0 0 0-1H6.707l1.647-1.646a.5.5 0 0 0 0-.708z" />
                </svg>
                <h4 className="unsubscribe-welcome-text">Processing...</h4>
            </center>
            <div className="text-center mt-3">
                <span className="unsubscribe-sub-text">
                    Please wait while we process your request.
                </span>
            </div>
        </div>
    );
};

export default Unsubscribe;
