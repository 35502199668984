import { useContext, useState } from "react";
import DragAndDrop from "../common/dragAndDrop";
import AppContext from "../../context/AppContext";
import notification from "../../helpers/notification";
import ApiService from "../../api/ApiService";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function NeedHelp() {
  const { t, i18n } = useTranslation();
  const { user } = useContext(AppContext);
  const [formData, setFormData] = useState({ subject: "", message: "" });
  const [uploadedFile, setUploadedFile] = useState("");
  const [loading, setLoading] = useState(false);

  const HandleChangeUpdateForm = (key, value) => {
    const tempData = { ...formData };
    tempData[key] = value;
    setFormData(tempData);
  };

  const handleSubmitFormData = async (e) => {
    e.preventDefault();
    if (formData.subject === "") {
      notification("error", t("Please enter the subject"));
      return false;
    }
    if (formData.message === "") {
      notification("error", t("Please enter the message"));
      return false;
    }
    setLoading(true);

    const formDataWithFile = new FormData();
    formDataWithFile.append("subject", formData.subject);
    formDataWithFile.append("message", formData.message);
    formDataWithFile.append("user_id", user.id);
    formDataWithFile.append("user_name", user.name);
    formDataWithFile.append("email", user.email);
    formDataWithFile.append("phone", user.phone);
    formDataWithFile.append("file", uploadedFile);

    notification("success", "Message submitted successfully.");
    const response = await ApiService.userNeedHelp(formDataWithFile);

    if (response.status) {
      // notification("success", "Message submitted successfully.");
      setFormData({ subject: "", message: "" });
      setUploadedFile("");
      setLoading(false);
    } else {
      notification("error", "Something went wrong");
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <ToastContainer />
      <div className="edit-profile-wrapper">
        <h5 className="header-text">{t("Need Help?")}</h5>
        <form onSubmit={handleSubmitFormData}>
          <div className="row">
            <div className="col-lg-6">
              <div className="input-bottom">
                <label className="label-text mb-1">{t("Subject")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Type here")}
                  name="subject"
                  onChange={(e) => {
                    HandleChangeUpdateForm("subject", e.target.value);
                  }}
                  value={formData.subject}
                  maxLength={100}
                />
              </div>
              <div className="input-bottom">
                <label className="label-text mb-1">{t("Your Message")}</label>
                <textarea
                  type="text"
                  className="form-control"
                  rows="4"
                  name="message"
                  placeholder={t("Type your message")}
                  onChange={(e) => {
                    HandleChangeUpdateForm("message", e.target.value);
                  }}
                  value={formData.message}
                />
              </div>
              <div className="input-bottom">
                <button type="submit" className="notification-load-more" disabled={loading}>
                  {t("Send Message")}
                </button>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="col-lg-12">
                <label className="label-text mb-1 mt-2">{t("Upload File")}</label>
                <div className="upload-photo mt-2">
                  <DragAndDrop setUploadedFile={setUploadedFile} uploadedFile={uploadedFile} />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
