import { useEffect, useState } from "react";
import "../css/forum.css";
import ForumForm from "../components/forum/forumForm";
import ForumList from "../components/forum/forumList";
import ApiService from "../api/ApiService";
import Pagination from "../components/common/pagination";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function Forum() {
  const { t, i18n } = useTranslation();

  const categories = [
    "All Categories",
    "Bhadra Purnima",
    "Gita Marathon",
    "CC Marathon",
    "Book Pricing",
    "Congregation Book Distribution",
    "Monthly Sankirtan Festival",
    "Vidya Daan",
    "Corporate Book Distribution",
    "Success Stories",
    "Others",
  ];

  const selectOptions = ["Sort By", "Newest", "Trending", "Most Liked", "Last Month", "Last Week"];

  const [activeTab, setActiveTab] = useState("view-question");
  const [userForumList, setUserForumList] = useState([1, 2, 3]);
  const [totalPagesCont, setTotalPagesCont] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [totalQuestionsCont, setTotalQuestionsCont] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [selectedFilterOption, setSelectedFilterOption] = useState(selectOptions[0]);
  const [isAPILoading, setIsAPILoading] = useState(true);

  const getAllForumList = async () => {
    setUserForumList([]);
    setTotalPagesCont(1);
    setTotalQuestionsCont(0);

    setIsAPILoading(true)
    const response = await ApiService.getAllForumList(searchKey, selectedCategory, pageNumber, selectedFilterOption);
    setIsAPILoading(false);

    if (response.status) {
      setUserForumList(response.data);
      setTotalPagesCont(response.total_page_count);
      setTotalQuestionsCont(response.total_count);
    }
  };

  useEffect(() => {
    getAllForumList();
  }, [searchKey, selectedCategory, pageNumber, selectedFilterOption, activeTab]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setSearchKey(event.target.value);
      setPageNumber(1);
      setSelectedCategory(categories[0]);
      setSelectedFilterOption(selectOptions[0]);
    }
  };

  const resetSearchFilter = () => {
    const searchInput = document.getElementById("search-input");
    // if (searchInput.value != "") {
    setSearchKey("");
    setSelectedCategory(categories[0]);
    setPageNumber(1);
    setSelectedFilterOption(selectOptions[0]);
    searchInput.value = "";
    // }
  };

  const tooltip = <Tooltip id="tooltip-example">{t("Reset All")}</Tooltip>;

  return (
    <div className="container forum-wrapper">
      <div className="row">
        <h4 className="blog-heading">{t("Our Forum")}</h4>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="forum-left">
            <div>
              {activeTab == "ask-question" && (
                <button className="question-text ask-question" onClick={() => setActiveTab("view-question")}>
                  {t("View Questions")}
                </button>
              )}
              {activeTab == "view-question" && (
                <button className="question-text ask-question" onClick={() => setActiveTab("ask-question")}>
                  {t("Ask Question")}
                </button>
              )}
            </div>
            {activeTab == "view-question" && (
              <>
                <h4 className="mt-4 category-text">{t("Categories")}</h4>
                <div className="mt-4">
                  {categories.map((category, i) => (
                    <label className="category-list-label mt-2" key={i}>
                      <input
                        type="radio"
                        className="custom-radio"
                        name="category"
                        value={category}
                        checked={selectedCategory === category}
                        onChange={(e) => {
                          setPageNumber(1);
                          setSelectedCategory(e.target.value);
                        }}
                      />
                      <p className="category-list m-0">{t(category)}</p>
                    </label>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>

        <div className="col-md-9">
          {activeTab == "view-question" && (
            <>
              <div className="forum-filters-row col-sm">
                <div className="questions-count mt-3">
                  {totalQuestionsCont} {t("Questions")}
                </div>
                <div className="col-sm forum-search-wrapper">
                  <div className="input-group forum-search-div">
                    <div className="input-group-prepend">
                      <span className="input-group-text forum-input-group-text" id="basic-addon1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="19"
                          fill="currentColor"
                          className="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                        </svg>
                      </span>
                    </div>
                    <input
                      id="search-input"
                      type="text"
                      className="form-control forum-search-container"
                      placeholder={t("Search...")}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      // onChange={(e) => {
                      //   setSearchKey(e.target.value);
                      // }}
                      onKeyPress={(e) => handleKeyPress(e)}
                    />
                    <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={tooltip}>
                      <div className="input-group-prepend" onClick={resetSearchFilter}>
                        <span className="input-group-text forum-input-group-text-reset" id="basic-addon1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="red"
                            className="m-1 bi bi-arrow-clockwise"
                            viewBox="0 0 16 16"
                          >
                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                          </svg>
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="input-group-prepend">
                    <span className="input-group-text forum-input-group-text" id="basic-addon1">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M20.172 -8.81422e-05H7.828C5.718 -8.81422e-05 4 1.71691 4 3.82891C4 4.85091 4.398 5.81191 5.121 6.53591L11 12.4139V15.9999C11 16.3149 11.148 16.6109 11.4 16.7999L15.4 19.7999C15.576 19.9319 15.788 19.9999 16 19.9999C16.152 19.9999 16.306 19.9649 16.447 19.8949C16.786 19.7259 17 19.3789 17 18.9999V12.4139L22.879 6.53491C23.602 5.81191 24 4.84991 24 3.82791C24 1.71691 22.282 -0.00108814 20.172 -0.00108814V-8.81422e-05ZM21.465 5.12191L15.293 11.2929C15.105 11.4809 15 11.7349 15 11.9999V16.9999L13 15.4999V11.9999C13 11.7349 12.895 11.4799 12.707 11.2929L6.535 5.12191C6.19 4.77591 6 4.31691 6 3.82891C6 2.82091 6.82 1.99991 7.828 1.99991H20.172C21.18 1.99991 22 2.81991 22 3.82891C22 4.31691 21.81 4.77691 21.465 5.12191ZM13 20.9999V22.9999C13 23.3789 12.786 23.7249 12.447 23.8949C12.305 23.9659 12.152 23.9999 12 23.9999C11.788 23.9999 11.576 23.9319 11.4 23.7999L7.4 20.7999C7.148 20.6119 7 20.3149 7 19.9999V16.4139L1.121 10.5359C0.398 9.81191 0 8.85091 0 7.82891C0 6.70291 0.493 5.63891 1.353 4.90891C1.776 4.55091 2.406 4.60291 2.763 5.02391C3.12 5.44491 3.069 6.07591 2.648 6.43391C2.237 6.78291 2.001 7.29191 2.001 7.82991C2.001 8.31791 2.191 8.77791 2.536 9.12291L8.708 15.2939C8.896 15.4819 9.001 15.7359 9.001 16.0009V19.5009L11.001 21.0009C11.001 20.4489 11.448 20.0009 12.001 20.0009C12.554 20.0009 13.001 20.4489 13.001 21.0009L13 20.9999Z"
                          fill="#43567D"
                        />
                      </svg>
                    </span>
                  </div>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setPageNumber(1);
                      // setSelectedCategory(categories[0]);
                      setSelectedFilterOption(e.target.value);
                    }}
                    value={selectedFilterOption}
                  >
                    {selectOptions.map((option, i) => (
                      <option className="filter-text" key={option} value={option}>
                        {t(option)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {searchKey != "" && <div className="questions-count">Search results for "{searchKey}"</div>}
              {!isAPILoading && (
                <div className="forum-right mt-4">
                  {userForumList.length > 0 ? (
                    userForumList.map((v, i) => <ForumList key={i} item={v} getAllForumList={getAllForumList} />)
                  ) : (
                    <div className="right-section-wrapper">
                      <h5 className="no-questions-found">{t("No Questions Found")}</h5>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {activeTab == "ask-question" && <ForumForm />}
        </div>
      </div>
      {totalPagesCont > 1 && activeTab == "view-question" && (
        <div className="bottom-nav">
          <Pagination pageNumber={pageNumber} totalPagesCont={totalPagesCont} setPageNumber={setPageNumber} />
        </div>
      )}
    </div>
  );
}
