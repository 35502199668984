import { useContext, useEffect, useState } from "react";
import AppContext from "../context/AppContext";
import ApiService from "../api/ApiService";
import moment from "moment/moment";
import { useLocation } from "react-router-dom";
import "../css/RecordingOfScores.css";
import "../css/app.css";
import Pagination from "../components/common/pagination";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function ViewTransactions() {
  const { t, i18n } = useTranslation();
  const selectTabs = [
    {
      label: "Regular Distribution",
      value: "Regular Update",
    },
    {
      label: "Bhadra Purnima Marathon",
      value: "Bhadra Purnima",
    },
    {
      label: "Chaitanya Charitamrita Marathon",
      value: "Chaitanya Charitamrita",
    },
    {
      label: "Gita Marathon",
      value: "Gita Marathon",
    },
  ];

  const currentYear = moment().year();
  const previousYear = moment().subtract(1, "year").year();
  const beforeLatYear = moment().subtract(2, "year").year();

  const yearsList = [currentYear, previousYear, beforeLatYear];

  const { user } = useContext(AppContext);
  const location = useLocation();

  const [transactionData, setTransactionData] = useState([]);
  const [transactionText, setTransactionText] = useState("Points");
  const [totalPagesCont, setTotalPagesCont] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLocation, setPageLocation] = useState(location?.state?.page);
  const [selectedEvent, setSelectedEvent] = useState(location?.state?.event);
  const [selectedYear, setSelectedYear] = useState(yearsList[0]);
  const [isAPILoading, setIsAPILoading] = useState(true);

  const itemsPerPage = 15;

  const getAllTransactionsByUser = async (user, location, page, event) => {
    setIsAPILoading(true);
    setTransactionData([]);
    let response = [];

    if (location == "update-score") {
      setTransactionText("Points");
      response = await ApiService.getAllTransactions(user.temple_id, page, event, selectedYear);
    } else {
      setTransactionText("Pledges");
      response = await ApiService.getAllTransactionsForPledge(user.temple_id, page, selectedEvent, selectedYear);
    }

    setIsAPILoading(false);
    setTransactionData(response.data);
    setTotalPagesCont(response.total_count);
  };

  const getAllHistoryDownload = async () => {
    const templeId = user.temple_id;
    const event = selectedEvent;
    const year = selectedYear;
    const type = transactionText;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/get-history-download/${templeId}/${event}/${year}/${type}`, {
        method: "GET",
        headers: {
          Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Get the blob from the response
      const blob = await response.blob();

      // Create a link element to download the file
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "data.xlsx"; // Filename for the downloaded file
      document.body.appendChild(a);
      a.click();

      // Clean up
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  // useEffect(() => {
  //   // const historyEvent = localStorage.getItem("history-event");
  //   if (historyEvent != null) {
  //     getAllTransactionsByUser(user, pageLocation, pageNumber, historyEvent);
  //     setSelectedEvent(historyEvent);
  //     // localStorage.removeItem("history-event");
  //   }
  // }, []);

  useEffect(() => {
    setTimeout(() => {
      getAllTransactionsByUser(user, pageLocation, pageNumber, selectedEvent);
    }, 500);
  }, [user, pageLocation, pageNumber, selectedEvent, selectedYear]);

  const camelToWords = (str) => {
    if (typeof str != "undefined") return str.replace(/([a-z])([A-Z])/g, "$1 $2");
  };

  return (
    <div className="container pledge-responsive live-ranking dashboard-container transaction-bottom">
      <div className="col-12 user-welcome-card">
        <div className="view-history-header modal-gap">
          <div className="history-back" onClick={() => window.history.back()}>
            <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.70125 13.9237C7.80669 13.8192 7.89039 13.6947 7.9475 13.5576C8.00462 13.4206 8.03402 13.2735 8.03402 13.125C8.03402 12.9765 8.00462 12.8294 7.9475 12.6924C7.89039 12.5553 7.80669 12.4308 7.70125 12.3263L4 8.625L15.25 8.625C15.5484 8.625 15.8345 8.50647 16.0455 8.29549C16.2565 8.08452 16.375 7.79837 16.375 7.5C16.375 7.20163 16.2565 6.91548 16.0455 6.7045C15.8345 6.49353 15.5484 6.375 15.25 6.375L4 6.375L7.70125 2.67375C7.80669 2.56917 7.89039 2.44474 7.9475 2.30765C8.00462 2.17056 8.03402 2.02351 8.03402 1.875C8.03402 1.72649 8.00462 1.57944 7.9475 1.44235C7.89039 1.30526 7.80669 1.18083 7.70125 1.07625C7.49047 0.866716 7.20533 0.749107 6.90813 0.749107C6.61092 0.749107 6.32578 0.866716 6.115 1.07625L1.28875 5.91375C0.866215 6.33377 0.627514 6.90423 0.625002 7.5C0.630477 8.09187 0.868951 8.65774 1.28875 9.075L6.115 13.9125C6.32429 14.1235 6.60859 14.2432 6.9058 14.2453C7.20302 14.2474 7.48899 14.1318 7.70125 13.9237Z"
                fill="#6E717F"
              />
            </svg>
          </div>
          <button
            className={"btn-primarysave custom-button " + (transactionText == "Pledges" ? "btn-primarysave-active" : "")}
            onClick={() => {
              // setSelectedYear(yearsList[0]);
              // setSelectedEvent(selectTabs[0].value);
              setTransactionData([]);
              setPageNumber(1);
              setTotalPagesCont(1);
              setPageLocation("pledge");
            }}
          >
            {t("View Your Historical Pledges")}
          </button>
          <button
            className={"btn-primarysave custom-button " + (transactionText == "Points" ? "btn-primarysave-active" : "")}
            onClick={() => {
              // setSelectedYear(yearsList[0]);
              // setSelectedEvent(selectTabs[0].value);
              setTransactionData([]);
              setPageNumber(1);
              setTotalPagesCont(1);
              setPageLocation("update-score");
            }}
          >
            {t("View Your Historical Points")}
          </button>
          <div className="year-download">
            <select className="form-select filter-text" value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} aria-label="Category">
              {yearsList.map((year, index) => (
                <option key={index} value={year} className="filter-text">
                  {year}
                </option>
              ))}
            </select>
            &nbsp;&nbsp;
            {transactionData.length > 0 && (
              <button
                className={"xl-download"}
                onClick={() => {
                  getAllHistoryDownload();
                }}
              >
                {t("Download")}
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_4168_535)">
                    <path
                      d="M6.99777 12.8364C7.82738 13.6666 9.17284 13.667 10.003 12.8374C10.0033 12.8371 10.0036 12.8368 10.0039 12.8364L12.2784 10.562C12.5408 10.2718 12.5182 9.82388 12.2281 9.5615C11.9579 9.31723 11.5465 9.31766 11.2768 9.5625L9.20425 11.6358L9.20919 0.708355C9.20916 0.317123 8.89204 0 8.50084 0C8.10964 0 7.79252 0.317123 7.79252 0.708322L7.78614 11.6223L5.72489 9.5625C5.44811 9.28592 4.99954 9.28608 4.72295 9.56286C4.44637 9.83965 4.44654 10.2882 4.72332 10.5648L6.99777 12.8364Z"
                      fill="#43567D"
                    />
                    <path
                      d="M16.2917 11.3333C15.9005 11.3333 15.5834 11.6504 15.5834 12.0416V14.875C15.5834 15.2662 15.2662 15.5833 14.875 15.5833H2.125C1.7338 15.5833 1.41668 15.2662 1.41668 14.875V12.0417C1.41668 11.6505 1.09955 11.3334 0.708355 11.3334C0.317123 11.3333 0 11.6505 0 12.0417V14.875C0 16.0486 0.951402 17 2.125 17H14.875C16.0486 17 17 16.0486 17 14.875V12.0417C17 11.6505 16.6829 11.3333 16.2917 11.3333Z"
                      fill="#43567D"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4168_535">
                      <rect width="17" height="17" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-12">
          <div className="ranking-filters">
            <div className="tabs-wrapper">
              {selectTabs.map((item, i) => (
                <div className="event-div">
                  <div
                    key={i}
                    className={"select-tab-div " + (selectedEvent == item.value ? " active-tab" : "")}
                    onClick={() => {
                      setSelectedEvent(item.value);
                      setPageNumber(1);
                    }}
                  >
                    <span className={selectedEvent == item.value ? "tab-event-text-active" : "tab-event-text"}>{t(item.label)}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <p className="view-history-title mb-4">
        {t("All Your Historical")} {t(transactionText)} {t("in")} {t(selectedEvent)}
      </p>
      {!isAPILoading ? (
        <>
          {transactionData.length > 0 ? (
            <div className="history-table">
              <Table responsive className="table history-custom-table">
                <thead>
                  <tr className="text-start sticky-header">
                    <th className="table-row-header">{t("Sr.No")}</th>
                    <th className="table-row-header text-start transaction-cell">{t("Book Name")}</th>
                    <th className="table-row-header text-start">{t("Event Name")}</th>
                    <th className="table-row-header text-start">{t("No. of Books")}</th>
                    <th className="table-row-header text-start">{t("Points")}</th>
                    <th className="table-row-header text-start">{t("Updated By")}</th>
                    <th className="table-row-header text-start">{t("Updated On")}</th>
                    {transactionText == "Points" && <th className="table-row-header text-start">{t("Updated For")}</th>}
                  </tr>
                </thead>
                <tbody>
                  {transactionData.map((row, index) => (
                    <tr key={row.event}>
                      <td>{(pageNumber - 1) * itemsPerPage + index + 1}</td>
                      {transactionText === "Points" ? (
                        <td className="history-cell-text transaction-cell">{t(camelToWords(row.book_id))}</td>
                      ) : (
                        <td className="history-cell-text transaction-cell">{t(camelToWords(row.book_name))}</td>
                      )}

                      <td className="history-cell-text">{t(row.event)}</td>
                      <td className="history-cell-text">{row.count_book?.toLocaleString("en-IN")}</td>
                      <td className="history-cell-text">{parseFloat(row.total_points.toFixed(2)).toLocaleString("en-IN")}</td>
                      <td className="history-cell-text">{i18n.language === "hi" ? row.hindiname : row.user_name}</td>
                      <td className="history-cell-text">{moment(row.updatedAt).format("DD-MM-YYYY (hh:mm a)")}</td>
                      {transactionText == "Points" && <td className="history-cell-text">{moment(row.selected_start_date).format("DD-MM-YYYY")}</td>}
                    </tr>
                  ))}
                  <tr>
                    {totalPagesCont > 1 && (
                      <td colSpan={transactionText === "Points" ? 8 : 7} className="text-center">
                        {totalPagesCont > 1 && <Pagination pageNumber={pageNumber} totalPagesCont={totalPagesCont} setPageNumber={setPageNumber} />}
                      </td>
                    )}
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : (
            <div className="right-section-wrapper">
              <h5 className="no-data-text">{t("No History Found.")}</h5>
            </div>
          )}
        </>
      ) : (
        <div className="right-section-wrapper">
          <h5 className="no-data-text">{t("Please wait loading...")}</h5>
        </div>
      )}
    </div>
  );
}
